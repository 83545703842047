import { createSlice } from '@reduxjs/toolkit';

type ITenantState = {
  currentTenantPid: string | null;
};

const initialState: ITenantState = {
  currentTenantPid: null,
};

const slice = createSlice({
  name: 'tenants',
  initialState,
  reducers: {
    saveCurrent(state: ITenantState, action: { payload: string }) {
      return {
        ...state,
        currentTenantPid: action.payload,
      };
    },
  },
});

const { reducer } = slice;
const { saveCurrent } = slice.actions;

if (!reducer) {
  throw new Error('No reducer found for tenant');
}

// eslint-disable-next-line import/no-unused-modules
export { reducer, slice, saveCurrent };
