import { createSlice } from '@reduxjs/toolkit';
import { LogState, LogMessage } from './types';

const initialState: LogState = {
  logs: [],
};

const slice = createSlice({
  name: 'log',
  initialState,
  reducers: {
    addLog: (state, { payload }) => ({
      ...state,
      logs: [...state.logs, payload],
    }),
    removeLog: (state, { payload }) => ({
      ...state,
      logs: state.logs.filter((log: LogMessage) => log !== payload),
    }),
    resetLogs: () => initialState,
    overwriteLogs: (state, { payload }) => Object.assign({}, state, { logs: payload }),
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { addLog, removeLog, resetLogs, overwriteLogs } = slice.actions;
