/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TreePlotter v3 API
 * OpenAPI spec version: 0.6.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  AddPhotosAsset,
  AddPhotosParams,
  ArchiveAssetParams,
  Asset,
  AssetPhotos,
  CreateAsset,
  CreateAsset201,
  CreateAssetParams,
  DeleteAssetParams,
  GetAllPhotosByAssetParams,
  GetAssetParams,
  GetAssetsParams,
  GetPhotoByAssetAndNameParams,
  Id,
  RemovePhotosAsset,
  RemovePhotosParams,
  UnarchiveAssetParams,
  UnauthorizedErrorResponse,
  UpdateAsset,
  UpdateAssetParams,
} from '.././model';
import { httpClient } from '../../../src/common/infrastructure/api/http-client';
import { queryMutator } from '../../../src/common/infrastructure/api/queryMutator';

/**
 * @summary Get assets
 */
export const getAssets = (params: GetAssetsParams, signal?: AbortSignal) => {
  return httpClient<Asset[]>({ url: `/assets`, method: 'GET', params, signal });
};

export const getGetAssetsQueryKey = (params: GetAssetsParams) => {
  return [`/assets`, ...(params ? [params] : [])] as const;
};

export const useGetAssetsQueryOptions = <
  TData = Awaited<ReturnType<typeof getAssets>>,
  TError = void
>(
  params: GetAssetsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getAssets>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAssetsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAssets>>> = ({ signal }) =>
    getAssets(params, signal);

  const customOptions = queryMutator({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<Awaited<ReturnType<typeof getAssets>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetAssetsQueryResult = NonNullable<Awaited<ReturnType<typeof getAssets>>>;
export type GetAssetsQueryError = void;

/**
 * @summary Get assets
 */
export const useGetAssets = <TData = Awaited<ReturnType<typeof getAssets>>, TError = void>(
  params: GetAssetsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getAssets>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetAssetsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create asset
 */
export const createAsset = (createAsset: CreateAsset, params: CreateAssetParams) => {
  return httpClient<CreateAsset201 | void>({
    url: `/assets/create`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createAsset,
    params,
  });
};

export const getCreateAssetMutationOptions = <
  TError = void | UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createAsset>>,
    TError,
    { data: CreateAsset; params: CreateAssetParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createAsset>>,
  TError,
  { data: CreateAsset; params: CreateAssetParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createAsset>>,
    { data: CreateAsset; params: CreateAssetParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return createAsset(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateAssetMutationResult = NonNullable<Awaited<ReturnType<typeof createAsset>>>;
export type CreateAssetMutationBody = CreateAsset;
export type CreateAssetMutationError = void | UnauthorizedErrorResponse;

/**
 * @summary Create asset
 */
export const useCreateAsset = <
  TError = void | UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createAsset>>,
    TError,
    { data: CreateAsset; params: CreateAssetParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createAsset>>,
  TError,
  { data: CreateAsset; params: CreateAssetParams },
  TContext
> => {
  const mutationOptions = getCreateAssetMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get asset
 */
export const getAsset = (assetId: Id, params: GetAssetParams, signal?: AbortSignal) => {
  return httpClient<Asset>({ url: `/assets/${assetId}`, method: 'GET', params, signal });
};

export const getGetAssetQueryKey = (assetId: Id, params: GetAssetParams) => {
  return [`/assets/${assetId}`, ...(params ? [params] : [])] as const;
};

export const useGetAssetQueryOptions = <
  TData = Awaited<ReturnType<typeof getAsset>>,
  TError = void
>(
  assetId: Id,
  params: GetAssetParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getAsset>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAssetQueryKey(assetId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAsset>>> = ({ signal }) =>
    getAsset(assetId, params, signal);

  const customOptions = queryMutator({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<Awaited<ReturnType<typeof getAsset>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetAssetQueryResult = NonNullable<Awaited<ReturnType<typeof getAsset>>>;
export type GetAssetQueryError = void;

/**
 * @summary Get asset
 */
export const useGetAsset = <TData = Awaited<ReturnType<typeof getAsset>>, TError = void>(
  assetId: Id,
  params: GetAssetParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getAsset>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetAssetQueryOptions(assetId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get list of asset photos filenames
 */
export const getAllPhotosByAsset = (
  assetId: Id,
  params: GetAllPhotosByAssetParams,
  signal?: AbortSignal
) => {
  return httpClient<AssetPhotos>({
    url: `/assets/${assetId}/photos`,
    method: 'GET',
    params,
    signal,
  });
};

export const getGetAllPhotosByAssetQueryKey = (assetId: Id, params: GetAllPhotosByAssetParams) => {
  return [`/assets/${assetId}/photos`, ...(params ? [params] : [])] as const;
};

export const useGetAllPhotosByAssetQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllPhotosByAsset>>,
  TError = void
>(
  assetId: Id,
  params: GetAllPhotosByAssetParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAllPhotosByAsset>>, TError, TData>;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllPhotosByAssetQueryKey(assetId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllPhotosByAsset>>> = ({ signal }) =>
    getAllPhotosByAsset(assetId, params, signal);

  const customOptions = queryMutator({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof getAllPhotosByAsset>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllPhotosByAssetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllPhotosByAsset>>
>;
export type GetAllPhotosByAssetQueryError = void;

/**
 * @summary Get list of asset photos filenames
 */
export const useGetAllPhotosByAsset = <
  TData = Awaited<ReturnType<typeof getAllPhotosByAsset>>,
  TError = void
>(
  assetId: Id,
  params: GetAllPhotosByAssetParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAllPhotosByAsset>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetAllPhotosByAssetQueryOptions(assetId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get photo
 */
export const getPhotoByAssetAndName = (
  assetId: Id,
  params: GetPhotoByAssetAndNameParams,
  signal?: AbortSignal
) => {
  return httpClient<Blob>({
    url: `/assets/${assetId}/photo`,
    method: 'GET',
    params,
    responseType: 'blob',
    signal,
  });
};

export const getGetPhotoByAssetAndNameQueryKey = (
  assetId: Id,
  params: GetPhotoByAssetAndNameParams
) => {
  return [`/assets/${assetId}/photo`, ...(params ? [params] : [])] as const;
};

export const useGetPhotoByAssetAndNameQueryOptions = <
  TData = Awaited<ReturnType<typeof getPhotoByAssetAndName>>,
  TError = void
>(
  assetId: Id,
  params: GetPhotoByAssetAndNameParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPhotoByAssetAndName>>, TError, TData>;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPhotoByAssetAndNameQueryKey(assetId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPhotoByAssetAndName>>> = ({ signal }) =>
    getPhotoByAssetAndName(assetId, params, signal);

  const customOptions = queryMutator({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof getPhotoByAssetAndName>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPhotoByAssetAndNameQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPhotoByAssetAndName>>
>;
export type GetPhotoByAssetAndNameQueryError = void;

/**
 * @summary Get photo
 */
export const useGetPhotoByAssetAndName = <
  TData = Awaited<ReturnType<typeof getPhotoByAssetAndName>>,
  TError = void
>(
  assetId: Id,
  params: GetPhotoByAssetAndNameParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPhotoByAssetAndName>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetPhotoByAssetAndNameQueryOptions(assetId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateAsset = (assetId: Id, updateAsset: UpdateAsset, params: UpdateAssetParams) => {
  return httpClient<void>({
    url: `/assets/${assetId}/update`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: updateAsset,
    params,
  });
};

export const getUpdateAssetMutationOptions = <
  TError = void | UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAsset>>,
    TError,
    { assetId: Id; data: UpdateAsset; params: UpdateAssetParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateAsset>>,
  TError,
  { assetId: Id; data: UpdateAsset; params: UpdateAssetParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAsset>>,
    { assetId: Id; data: UpdateAsset; params: UpdateAssetParams }
  > = (props) => {
    const { assetId, data, params } = props ?? {};

    return updateAsset(assetId, data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateAssetMutationResult = NonNullable<Awaited<ReturnType<typeof updateAsset>>>;
export type UpdateAssetMutationBody = UpdateAsset;
export type UpdateAssetMutationError = void | UnauthorizedErrorResponse;

export const useUpdateAsset = <
  TError = void | UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAsset>>,
    TError,
    { assetId: Id; data: UpdateAsset; params: UpdateAssetParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateAsset>>,
  TError,
  { assetId: Id; data: UpdateAsset; params: UpdateAssetParams },
  TContext
> => {
  const mutationOptions = getUpdateAssetMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Delete asset
 */
export const deleteAsset = (assetId: Id, params: DeleteAssetParams) => {
  return httpClient<void>({ url: `/assets/${assetId}/delete`, method: 'POST', params });
};

export const getDeleteAssetMutationOptions = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAsset>>,
    TError,
    { assetId: Id; params: DeleteAssetParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteAsset>>,
  TError,
  { assetId: Id; params: DeleteAssetParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteAsset>>,
    { assetId: Id; params: DeleteAssetParams }
  > = (props) => {
    const { assetId, params } = props ?? {};

    return deleteAsset(assetId, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteAssetMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAsset>>>;

export type DeleteAssetMutationError = void;

/**
 * @summary Delete asset
 */
export const useDeleteAsset = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAsset>>,
    TError,
    { assetId: Id; params: DeleteAssetParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteAsset>>,
  TError,
  { assetId: Id; params: DeleteAssetParams },
  TContext
> => {
  const mutationOptions = getDeleteAssetMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Archive asset
 */
export const archiveAsset = (assetId: Id, params: ArchiveAssetParams) => {
  return httpClient<void>({ url: `/assets/${assetId}/archive`, method: 'POST', params });
};

export const getArchiveAssetMutationOptions = <
  TError = UnauthorizedErrorResponse | void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof archiveAsset>>,
    TError,
    { assetId: Id; params: ArchiveAssetParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof archiveAsset>>,
  TError,
  { assetId: Id; params: ArchiveAssetParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof archiveAsset>>,
    { assetId: Id; params: ArchiveAssetParams }
  > = (props) => {
    const { assetId, params } = props ?? {};

    return archiveAsset(assetId, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type ArchiveAssetMutationResult = NonNullable<Awaited<ReturnType<typeof archiveAsset>>>;

export type ArchiveAssetMutationError = UnauthorizedErrorResponse | void;

/**
 * @summary Archive asset
 */
export const useArchiveAsset = <
  TError = UnauthorizedErrorResponse | void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof archiveAsset>>,
    TError,
    { assetId: Id; params: ArchiveAssetParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof archiveAsset>>,
  TError,
  { assetId: Id; params: ArchiveAssetParams },
  TContext
> => {
  const mutationOptions = getArchiveAssetMutationOptions(options);

  return useMutation(mutationOptions);
};
export const unarchiveAsset = (assetId: Id, params: UnarchiveAssetParams) => {
  return httpClient<void>({ url: `/assets/${assetId}/unarchive`, method: 'POST', params });
};

export const getUnarchiveAssetMutationOptions = <
  TError = UnauthorizedErrorResponse | void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof unarchiveAsset>>,
    TError,
    { assetId: Id; params: UnarchiveAssetParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof unarchiveAsset>>,
  TError,
  { assetId: Id; params: UnarchiveAssetParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof unarchiveAsset>>,
    { assetId: Id; params: UnarchiveAssetParams }
  > = (props) => {
    const { assetId, params } = props ?? {};

    return unarchiveAsset(assetId, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type UnarchiveAssetMutationResult = NonNullable<Awaited<ReturnType<typeof unarchiveAsset>>>;

export type UnarchiveAssetMutationError = UnauthorizedErrorResponse | void;

export const useUnarchiveAsset = <
  TError = UnauthorizedErrorResponse | void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof unarchiveAsset>>,
    TError,
    { assetId: Id; params: UnarchiveAssetParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof unarchiveAsset>>,
  TError,
  { assetId: Id; params: UnarchiveAssetParams },
  TContext
> => {
  const mutationOptions = getUnarchiveAssetMutationOptions(options);

  return useMutation(mutationOptions);
};
export const addPhotos = (assetId: Id, addPhotosAsset: AddPhotosAsset, params: AddPhotosParams) => {
  const formData = new FormData();
  formData.append('photoAttributeKey', addPhotosAsset.photoAttributeKey);
  formData.append('tenantId', addPhotosAsset.tenantId);
  addPhotosAsset.files.forEach((value) => formData.append('files', value));

  return httpClient<void>({
    url: `/assets/${assetId}/add-photos`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
    params,
  });
};

export const getAddPhotosMutationOptions = <
  TError = void | UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addPhotos>>,
    TError,
    { assetId: Id; data: AddPhotosAsset; params: AddPhotosParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addPhotos>>,
  TError,
  { assetId: Id; data: AddPhotosAsset; params: AddPhotosParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addPhotos>>,
    { assetId: Id; data: AddPhotosAsset; params: AddPhotosParams }
  > = (props) => {
    const { assetId, data, params } = props ?? {};

    return addPhotos(assetId, data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddPhotosMutationResult = NonNullable<Awaited<ReturnType<typeof addPhotos>>>;
export type AddPhotosMutationBody = AddPhotosAsset;
export type AddPhotosMutationError = void | UnauthorizedErrorResponse;

export const useAddPhotos = <
  TError = void | UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addPhotos>>,
    TError,
    { assetId: Id; data: AddPhotosAsset; params: AddPhotosParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof addPhotos>>,
  TError,
  { assetId: Id; data: AddPhotosAsset; params: AddPhotosParams },
  TContext
> => {
  const mutationOptions = getAddPhotosMutationOptions(options);

  return useMutation(mutationOptions);
};
export const removePhotos = (
  assetId: Id,
  removePhotosAsset: RemovePhotosAsset,
  params: RemovePhotosParams
) => {
  return httpClient<void>({
    url: `/assets/${assetId}/remove-photos`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: removePhotosAsset,
    params,
  });
};

export const getRemovePhotosMutationOptions = <
  TError = void | UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removePhotos>>,
    TError,
    { assetId: Id; data: RemovePhotosAsset; params: RemovePhotosParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof removePhotos>>,
  TError,
  { assetId: Id; data: RemovePhotosAsset; params: RemovePhotosParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof removePhotos>>,
    { assetId: Id; data: RemovePhotosAsset; params: RemovePhotosParams }
  > = (props) => {
    const { assetId, data, params } = props ?? {};

    return removePhotos(assetId, data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type RemovePhotosMutationResult = NonNullable<Awaited<ReturnType<typeof removePhotos>>>;
export type RemovePhotosMutationBody = RemovePhotosAsset;
export type RemovePhotosMutationError = void | UnauthorizedErrorResponse;

export const useRemovePhotos = <
  TError = void | UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removePhotos>>,
    TError,
    { assetId: Id; data: RemovePhotosAsset; params: RemovePhotosParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof removePhotos>>,
  TError,
  { assetId: Id; data: RemovePhotosAsset; params: RemovePhotosParams },
  TContext
> => {
  const mutationOptions = getRemovePhotosMutationOptions(options);

  return useMutation(mutationOptions);
};
