import { createSlice } from '@reduxjs/toolkit';

type IAssetClassState = {
  currentAssetClassId: string | null;
};

const initialState: IAssetClassState = {
  currentAssetClassId: null,
};

const slice = createSlice({
  name: 'assetClasses',
  initialState,
  reducers: {
    saveCurrent(state: IAssetClassState, action: { payload: string }) {
      return {
        ...state,
        currentAssetClassId: action.payload,
      };
    },
  },
});

const { reducer } = slice;
const { saveCurrent } = slice.actions;

if (!reducer) {
  throw new Error('No reducer found for assetClass');
}

// eslint-disable-next-line import/no-unused-modules
export { reducer, slice, saveCurrent };
