import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { HEADER } from 'config';
import LayoutBox from './LayoutBox';
import Header from './Header';
import Main from './Main';
import NavVertical from './nav/NavVertical';

type BaseLayoutProps = {
  noScroll?: boolean;
  onlyBackButton?: boolean;
  title?: string;
};

// km 2/27/23: The current goal with this layout is to use it as widely as
//   possible and minimize code duplication when creating layouts that need to
//   deviate from BaseLayout
export default function BaseLayout({ noScroll, onlyBackButton, title }: BaseLayoutProps) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderNavVertical = <NavVertical openNav={open} onCloseNav={handleClose} />;

  const sx = {
    ...(noScroll && {
      pt: `${HEADER.H_MOBILE}px`,
      pl: 0,
      pr: 0,
      pb: 0,
    }),
  };

  return (
    <Main sx={sx}>
      <Header onOpenNav={handleOpen} onlyBackButton={onlyBackButton} title={title} />

      <LayoutBox>
        {renderNavVertical}

        <Outlet />
      </LayoutBox>
    </Main>
  );
}
