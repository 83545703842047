// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformQueryKeyPathToArray = (queryKey: any) => [
  ...queryKey[0].split('/').slice(1),
  ...queryKey.slice(1),
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const queryMutator = (args: any) => {
  const newArgs = {
    ...args,
    queryKey: transformQueryKeyPathToArray(args.queryKey),
  };
  return newArgs;
};
