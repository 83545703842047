import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link, BoxProps } from '@mui/material';
// routes
import { PATH_AFTER_LOGIN } from '../../config';

// ----------------------------------------------------------------------

// eslint-disable-next-line import/no-unused-modules
export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const logo = (
      <Box
        component="img"
        src="/logo/TP Color Icon.svg"
        sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
        alt="TreePlotter by PlanIT Geo"
      />
    );

    if (disabledLink) {
      return <>{logo}</>;
    }

    return (
      <Link to={PATH_AFTER_LOGIN} component={RouterLink} sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
