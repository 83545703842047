import { useMemo } from 'react';
import { dispatch, store } from 'redux/store';
import { ReferenceTableMapper } from './mapper';
import {
  useGetReferenceTable,
  useGetReferenceTables,
  getGetReferenceTablesQueryKey,
  getReferenceTables,
} from 'api-client';
import { slice } from 'assetClass/infrastructure/referenceTable/redux';
import { queryClient as moduleQueryClient } from 'common/infrastructure/remoteState/persistQueryClient';
import { transformQueryKeyPathToArray } from 'common/infrastructure/api/queryMutator';
import ReferenceTableItemRepository from './referenceTableItem/repository';
import FieldRepository from '../field/repository';
import loggerService, { LoggerLevel } from 'common/infrastructure/log';

const ReferenceTableRepository = {
  useGetReferenceTable(referenceTableId: string) {
    const { auth } = store.getState();

    const useQueryResponse = useGetReferenceTable(referenceTableId, {
      query: { enabled: auth.isAuthenticated && !!auth.user },
    });
    const referenceTableEntity = useMemo(() => {
      if (!useQueryResponse?.data) {
        return null;
      }
      return ReferenceTableMapper.fromDTO(useQueryResponse.data);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(useQueryResponse?.data)]);

    return {
      ...useQueryResponse,
      referenceTableEntity,
    };
  },

  useGetReferenceTables(tenantId?: string) {
    const { auth } = store.getState();

    const useQueryResponse = useGetReferenceTables(
      {
        tenantId: tenantId || '',
      },
      {
        query: {
          enabled: !!tenantId && auth.isAuthenticated && !!auth.user,
        },
      }
    );

    const referenceTableEntities = useMemo(() => {
      if (!useQueryResponse?.data || !Array.isArray(useQueryResponse.data)) {
        return [];
      }
      const referenceTableEntities = useQueryResponse.data.map(ReferenceTableMapper.fromDTO);

      if (referenceTableEntities && !!tenantId && auth.isAuthenticated && !!auth.user) {
        referenceTableEntities.forEach((refTable: { id: string }) => {
          ReferenceTableItemRepository.prefetchReferenceTableItems(refTable?.id).catch((error) => {
            loggerService.log(LoggerLevel.ERROR, error);
          });

          FieldRepository.prefetchFields(refTable?.id).catch((error) => {
            loggerService.log(LoggerLevel.ERROR, error);
          });
        });
      }

      return referenceTableEntities;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(useQueryResponse?.data)]);

    return {
      ...useQueryResponse,
      referenceTableEntities,
    };
  },

  saveCurrent(referenceTableId: string) {
    dispatch(slice.actions.saveCurrent(referenceTableId));
  },

  async prefetchReferenceTables(tenantId: string) {
    const queryKey = transformQueryKeyPathToArray(getGetReferenceTablesQueryKey({ tenantId }));
    await moduleQueryClient.prefetchQuery(queryKey, () => getReferenceTables({ tenantId }));
  },
};

export default ReferenceTableRepository;
