import 'locales/i18n';
import * as uuid from 'uuid';
import 'simplebar/src/simplebar.css';
import 'react-18-image-lightbox/style.css';
import 'utils/mapboxgl';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'assetClass/presentation/map/mapboxControlOverrides.css';
import 'assetClass/presentation/map/openlayers/openlayers.css';
import '../node_modules/ol/ol.css';

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { store, persistor } from 'redux/store';
import { SettingsProvider } from 'components/settings';
import { AuthProvider } from 'identityAndAccess/infrastructure/AwsCognitoJwtContext';
import { LogProvider } from 'common/infrastructure/log/LogContext';
import RemoteStateProvider from 'common/infrastructure/remoteState/persistQueryClient';
import CacheBuster from 'common/infrastructure/cacheBuster/CacheBuster';
import App from 'App';
import * as serviceWorkerRegistration from 'serviceWorkerRegistration';
import reportWebVitals from 'reportWebVitals';
import { setNodeId } from '@planitgeo/treeplotter-shared/lib/infrastructure/clock/hybrid-logical-clock';
import { init as initHandlers } from 'common/application/handlers';

// Set the node id for the hybrid logical clock
const nodeId = localStorage.getItem('nodeId') || uuid.v4();
setNodeId(nodeId);
localStorage.setItem('nodeId', nodeId);

initHandlers();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <RemoteStateProvider>
    <ReduxProvider store={store}>
      <AuthProvider>
        <LogProvider>
          <CacheBuster>
            {({ loading, isLatestVersion, refreshCacheAndReload }) => {
              if (loading) return null;
              if (!loading && !isLatestVersion && navigator.onLine) {
                refreshCacheAndReload();
              }
              return (
                <HelmetProvider>
                  <PersistGate loading={null} persistor={persistor}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <SettingsProvider>
                        <BrowserRouter>
                          <App />
                        </BrowserRouter>
                      </SettingsProvider>
                    </LocalizationProvider>
                  </PersistGate>
                </HelmetProvider>
              );
            }}
          </CacheBuster>
        </LogProvider>
      </AuthProvider>
    </ReduxProvider>
  </RemoteStateProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
