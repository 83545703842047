import i18n from 'locales/i18n';
import { Button } from '@mui/material';
import { queryClient as moduleQueryClient } from 'common/infrastructure/remoteState/persistQueryClient';

export const resetFieldConfiguration = async () => {
  // invalidates all queries in the cache and refetches
  await moduleQueryClient.invalidateQueries();
};

export const ResetButton = (action?: VoidFunction) => {
  const message = i18n.t('reset');
  return (
    <Button
      onClick={async () => {
        await action?.();
      }}
    >
      {message}
    </Button>
  );
};
