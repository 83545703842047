/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TreePlotter v3 API
 * OpenAPI spec version: 0.6.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  GetReferenceTablesParams,
  Id,
  ReferenceTable,
  UnauthorizedErrorResponse,
} from '.././model';
import { httpClient } from '../../../src/common/infrastructure/api/http-client';
import { queryMutator } from '../../../src/common/infrastructure/api/queryMutator';

/**
 * Fetch reference tables for a particular tenant.
 * @summary Get reference tables
 */
export const getReferenceTables = (params: GetReferenceTablesParams, signal?: AbortSignal) => {
  return httpClient<ReferenceTable[]>({ url: `/reference-tables`, method: 'GET', params, signal });
};

export const getGetReferenceTablesQueryKey = (params: GetReferenceTablesParams) => {
  return [`/reference-tables`, ...(params ? [params] : [])] as const;
};

export const useGetReferenceTablesQueryOptions = <
  TData = Awaited<ReturnType<typeof getReferenceTables>>,
  TError = UnauthorizedErrorResponse
>(
  params: GetReferenceTablesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getReferenceTables>>, TError, TData>;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReferenceTablesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReferenceTables>>> = ({ signal }) =>
    getReferenceTables(params, signal);

  const customOptions = queryMutator({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof getReferenceTables>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReferenceTablesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getReferenceTables>>
>;
export type GetReferenceTablesQueryError = UnauthorizedErrorResponse;

/**
 * @summary Get reference tables
 */
export const useGetReferenceTables = <
  TData = Awaited<ReturnType<typeof getReferenceTables>>,
  TError = UnauthorizedErrorResponse
>(
  params: GetReferenceTablesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getReferenceTables>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetReferenceTablesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getReferenceTable = (referenceTableId: Id, signal?: AbortSignal) => {
  return httpClient<ReferenceTable>({
    url: `/reference-tables/${referenceTableId}`,
    method: 'GET',
    signal,
  });
};

export const getGetReferenceTableQueryKey = (referenceTableId: Id) => {
  return [`/reference-tables/${referenceTableId}`] as const;
};

export const useGetReferenceTableQueryOptions = <
  TData = Awaited<ReturnType<typeof getReferenceTable>>,
  TError = UnauthorizedErrorResponse
>(
  referenceTableId: Id,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getReferenceTable>>, TError, TData>;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReferenceTableQueryKey(referenceTableId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReferenceTable>>> = ({ signal }) =>
    getReferenceTable(referenceTableId, signal);

  const customOptions = queryMutator({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof getReferenceTable>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReferenceTableQueryResult = NonNullable<
  Awaited<ReturnType<typeof getReferenceTable>>
>;
export type GetReferenceTableQueryError = UnauthorizedErrorResponse;

export const useGetReferenceTable = <
  TData = Awaited<ReturnType<typeof getReferenceTable>>,
  TError = UnauthorizedErrorResponse
>(
  referenceTableId: Id,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getReferenceTable>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetReferenceTableQueryOptions(referenceTableId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Delete reference table
 */
export const deleteReferenceTable = (referenceTableId: Id) => {
  return httpClient<void>({ url: `/reference-tables/${referenceTableId}/delete`, method: 'POST' });
};

export const getDeleteReferenceTableMutationOptions = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReferenceTable>>,
    TError,
    { referenceTableId: Id },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteReferenceTable>>,
  TError,
  { referenceTableId: Id },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteReferenceTable>>,
    { referenceTableId: Id }
  > = (props) => {
    const { referenceTableId } = props ?? {};

    return deleteReferenceTable(referenceTableId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteReferenceTableMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteReferenceTable>>
>;

export type DeleteReferenceTableMutationError = void;

/**
 * @summary Delete reference table
 */
export const useDeleteReferenceTable = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReferenceTable>>,
    TError,
    { referenceTableId: Id },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteReferenceTable>>,
  TError,
  { referenceTableId: Id },
  TContext
> => {
  const mutationOptions = getDeleteReferenceTableMutationOptions(options);

  return useMutation(mutationOptions);
};
