// routes
import { PATH_APP } from '../routes/paths';
import config, { Env, Region } from './regionalConfig';

const getRegionalConfigFromEnv = () => {
  let env: Env;
  const { hostname: host } = window.location;

  if (host.includes('localhost') || host.includes('ngrok')) {
    env = 'local';
    const region: Region = 'us';
    return {
      env,
      config: config[env][region],
    };
  } else {
    const hostComponents = host.split('.');

    if (hostComponents[1] === 'staging') {
      env = 'staging';
      const region: Region = 'us';
      return {
        env,
        config: config[env][region],
      };
    } else if (hostComponents[1] === 'dev') {
      env = 'dev';
      const region: Region = 'us';
      return {
        env,
        config: config[env][region],
      };
    } else {
      env = 'prod';
      const region = hostComponents[1] as Region;
      return {
        env,
        config: config[env][region],
      };
    }
  }
};
const { config: envConfig, env } = getRegionalConfigFromEnv();

type EnvObject = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isVariableSet = (value: any) => !(value === '' || value === undefined);
const throwIfAnyVariableIsNotSet = (
  envVariablesObject: EnvObject,
  variableKeyExceptions: string[] = []
) => {
  Object.keys(envVariablesObject).forEach((key: string) => {
    if (!variableKeyExceptions.includes(key) && !isVariableSet(envVariablesObject[key])) {
      throw new Error(`Variable not set: ${key}`);
    }
  });
};

// API
// ----------------------------------------------------------------------

export const HOST_API_KEY = process.env.REACT_APP_HOST_API_KEY || '';

export const APPLICATION = {
  env,
};

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_APP.root;

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};

export const TP_API = {
  baseUrl: envConfig.tp_api.api_base_url || '',
};

export const TP3_API = {
  baseUrl: envConfig.tp3_api.api_base_url || '',
};

export const AUTH_API = {
  baseUrl: envConfig.auth.api_base_url || '',
  tokenPath: envConfig.auth.api_token_path || '',
  clientId: envConfig.cognito.client_id || '',
  devRedirectUrl: process.env.REACT_APP_DEV_REDIRECT_URL || '',
};
throwIfAnyVariableIsNotSet(AUTH_API, ['devRedirectUrl']);

export const AUTH_COGNITO = {
  baseUrl: envConfig.cognito.base_url || '',
  clientId: envConfig.cognito.client_id || '',
  redirectUrl: envConfig.cognito.redirect_base_url || '',
  clientRedirectUrl: envConfig.cognito.redirect_client_url || '',
};
throwIfAnyVariableIsNotSet(AUTH_COGNITO);

// Map
// ----------------------------------------------------------------------
type Themes = 'streets' | 'outdoors' | 'light' | 'dark' | 'satellite' | 'satelliteStreets';
export const MAP: {
  mapbox: {
    accessToken: string;
    themes: {
      [key in Themes]: string;
    };
    defaultTheme: Themes;
    baseSettings: {
      minZoom: number;
      center: number[];
      zoom: number;
    };
  };
  openLayers: {
    baseSettings: {
      minZoom: number;
      center: number[];
      zoom: number;
    };
    apiKey: string;
    baseMapsUrl: string;
    baseMapStyles: string[];
    defaultBaseMapStyle: string;
  };
} = {
  mapbox: {
    accessToken: envConfig.mapbox.access_token || '',
    themes: {
      streets: 'mapbox://styles/mapbox/streets-v12',
      outdoors: 'mapbox://styles/mapbox/outdoors-v12',
      light: 'mapbox://styles/mapbox/light-v11',
      dark: 'mapbox://styles/mapbox/dark-v11',
      satellite: 'mapbox://styles/mapbox/satellite-v9',
      satelliteStreets: 'mapbox://styles/mapbox/satellite-streets-v12',
    },
    defaultTheme: 'satelliteStreets',
    baseSettings: {
      minZoom: 1,
      center: [-74.5, 40],
      zoom: 9,
    },
  },
  openLayers: {
    baseSettings: {
      minZoom: 1,
      center: [-104.95040866597498, 39.909569576144996],
      zoom: 9,
    },
    apiKey: envConfig.openlayers.api_key || '',
    baseMapsUrl: 'https://basemaps-api.arcgis.com/arcgis/rest/services/styles',
    baseMapStyles: [
      'ArcGIS:Imagery',
      'ArcGIS:Imagery:Standard',
      'ArcGIS:Imagery:Labels',
      'ArcGIS:Topographic',
      'ArcGIS:Topographic:Base',
      'ArcGIS:Terrain',
      'ArcGIS:Terrain:Base',
      'ArcGIS:Terrain:Detail',
      'ArcGIS:Hillshade:Dark',
      'ArcGIS:Hillshade:Light',
      'ArcGIS:DarkGray',
      'ArcGIS:DarkGray:Base',
      'ArcGIS:DarkGray:Labels',
      'ArcGIS:LightGray',
      'ArcGIS:LightGray:Base',
      'ArcGIS:LightGray:Labels',
      'ArcGIS:Navigation',
      'ArcGIS:NavigationNight',
      'ArcGIS:Streets',
      'ArcGIS:StreetsRelief',
      'ArcGIS:StreetsRelief:Base',
      'ArcGIS:StreetsNight',
    ],
    defaultBaseMapStyle: 'ArcGIS:Imagery',
  },
};
