import { Navigate } from 'react-router-dom';
import GuestGuard from '../presentation/GuestGuard'; // should this go through elements too?
import { LoginPage, AwsCognitoCallbackPage /* , RegisterPage */ } from './elements';

const IdentityAndAccessRoutes = [
  {
    path: 'auth',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <LoginPage />
          </GuestGuard>
        ),
      },
      /* TEMP DISABLE {
        path: 'register',
        element: (
          <GuestGuard>
            <RegisterPage />
          </GuestGuard>
        ),
      }, */
      {
        path: 'cognito-callback',
        element: (
          <GuestGuard>
            <AwsCognitoCallbackPage />
          </GuestGuard>
        ),
      },
    ],
  },
  { path: '*', element: <Navigate to="/404" replace /> }, // this has to be last in the routes
];

export default IdentityAndAccessRoutes;
