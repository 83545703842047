import { createSlice } from '@reduxjs/toolkit';

type IReferenceTableState = {
  currentReferenceTablePid: string | null;
};

const initialState: IReferenceTableState = {
  currentReferenceTablePid: null,
};

const slice = createSlice({
  name: 'referenceTables',
  initialState,
  reducers: {
    saveCurrent(state: IReferenceTableState, action: { payload: string }) {
      return {
        ...state,
        currentReferenceTablePid: action.payload,
      };
    },
  },
});

const { reducer } = slice;

if (!reducer) {
  throw new Error('No reducer found for reference table');
}

// eslint-disable-next-line import/no-unused-modules
export { reducer, slice };
