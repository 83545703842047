import { Stack } from '@mui/material';
import Image from 'common/presentation/image';
import { StyledRoot, StyledSection, StyledContent } from './styles';

type Props = {
  title?: string;
  illustration?: string;
  children: React.ReactNode;
};

export default function LoginLayout({ children, illustration, title }: Props) {
  return (
    <StyledRoot>
      <StyledSection>
        <Image
          disabledEffect
          visibleByDefault
          alt="Looking up at city buildings through tree branches"
          src={illustration || '/assets/planitgeo_assets/AdobeStock_183868504.jpeg'}
        />
      </StyledSection>
      <StyledContent>
        <Stack sx={{ width: 1 }}> {children} </Stack>
      </StyledContent>
    </StyledRoot>
  );
}
