import { createSlice } from '@reduxjs/toolkit';
import { ActionMapType, AuthStateType, AuthUserType } from '../types';

enum AuthPayloadTypes {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [AuthPayloadTypes.INITIAL]: {
    isAuthenticated: boolean;
    user: AuthUserType;
  };
  [AuthPayloadTypes.LOGIN]: {
    user: AuthUserType;
  };
  [AuthPayloadTypes.REGISTER]: {
    user: AuthUserType;
  };
  [AuthPayloadTypes.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth(state: AuthStateType, action: { payload: ActionsType }) {
      if (action.payload.type === AuthPayloadTypes.INITIAL) {
        return {
          isInitialized: true,
          isAuthenticated: action.payload.data.isAuthenticated,
          user: action.payload.data.user,
        };
      }
      if (action.payload.type === AuthPayloadTypes.LOGIN) {
        return {
          ...state,
          isAuthenticated: true,
          user: action.payload.data.user,
        };
      }
      if (action.payload.type === AuthPayloadTypes.LOGOUT) {
        return {
          ...state,
          isAuthenticated: false,
          user: null,
        };
      }
      return state;
    },
  },
});

const { reducer } = slice;
const { setAuth } = slice.actions;

if (!reducer) {
  throw new Error('No reducer found for auth');
}

export { reducer, setAuth, AuthPayloadTypes, initialState as authInitialState };
