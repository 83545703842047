import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Stack, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import { PATH_APP } from '../../../routes/paths';
import { bgBlur } from 'utils/cssStyles';
import { HEADER } from 'config';
import Iconify from 'common/presentation/iconify';

type Props = {
  onOpenNav?: VoidFunction;
  onlyBackButton?: boolean;
  title?: string;
};

export default function Header({ onOpenNav, onlyBackButton, title }: Props) {
  const theme = useTheme();
  const navigate = useNavigate();

  const renderContent = (
    <>
      {onlyBackButton && (
        <IconButton onClick={() => navigate(-1)} sx={{ color: 'text.primary' }}>
          <Iconify icon="material-symbols:keyboard-arrow-left" />
        </IconButton>
      )}

      {!onlyBackButton && (
        <>
          <IconButton onClick={onOpenNav} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButton>
          {title && (
            <Typography variant="overline" sx={{ color: 'black', fontSize: '0.85rem' }}>
              {title}
            </Typography>
          )}
          <Stack
            flexGrow={1}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={{ xs: 0.5, sm: 1.5 }}
          >
            <IconButton
              onClick={() => {
                navigate(PATH_APP.settings);
              }}
            >
              <Iconify icon="material-symbols:settings" />
            </IconButton>
          </Stack>
        </>
      )}
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
