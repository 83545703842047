import { Message } from 'common/infrastructure/bus';
import { IEvent } from 'common/infrastructure/event.bus';
import { commandBus } from 'common/infrastructure/command.bus';
import { AssetCreatedEvent } from '@planitgeo/treeplotter-shared/lib/modules/asset-class/asset/domain/event/asset-created.event';
import { FieldEntity } from '@planitgeo/treeplotter-shared/lib/modules/field/field/domain/field.model';
import { FieldSyncUpdateAssetCommand } from '@planitgeo/treeplotter-shared/lib/modules/asset-class/asset/domain/command/field-sync-update-asset.command';
import FieldRepository from 'assetClass/infrastructure/field/repository';

export const processEvent = async (event: Message<IEvent<AssetCreatedEvent>>) => {
  const {
    payload: { id: assetId, assetClassId, attributes },
  } = event.data.payload;

  const fields = await FieldRepository.getFields(assetClassId);

  const changedFieldUuids = Object.keys(attributes);
  const changedFieldsWithSyncFields = fields.filter(
    (field: FieldEntity) => changedFieldUuids.includes(field.id) && field.syncFieldUuids.length
  );

  const updatedFields: string[] = [];
  const updateCommands = changedFieldsWithSyncFields.reduce(
    (acc: FieldSyncUpdateAssetCommand[], field) => {
      field.syncFieldUuids.forEach((syncFieldUuid) => {
        if (updatedFields.includes(syncFieldUuid)) {
          return;
        }
        acc.push(
          new FieldSyncUpdateAssetCommand({
            id: assetId,
            assetClassId,
            attributes: {
              [syncFieldUuid]: attributes[field.id],
            },
          })
        );
        updatedFields.push(syncFieldUuid);
      });
      return acc;
    },
    []
  );

  updateCommands.forEach((command) =>
    commandBus.execute({
      payload: command,
      type: 'FieldSyncUpdateAssetCommand',
    })
  );
};
