const es = {
  auth: {
    email: `Correo electrónico`,
    password: `Contraseña`,
    login: `Iniciar sesión`,
    logout: `Cerrar sesión`,
    create_an_account: `Crear una cuenta`,
    forgot_password: `¿Olvidó su contraseña?`,
  },
  login: {
    title: `Iniciar sesión en TreePlotter Mobile`,
    greeting: `Hola, bienvenido de nuevo`,
    new_user: `¿Nuevo usuario?`,
  },
  user: `usuario`,
};

export default es;
