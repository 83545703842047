import { Bus } from './bus';
import * as uuid from 'uuid';

export interface IEvent<T> {
  payload: T;
  id: string;
}

class EventBus<T> extends Bus<IEvent<T>> {
  /**
   * Subscribes a handler to the specified event type
   * @param eventType The type of event to register the handler for
   * @param callback the callback to execute when an event of the specified type is subscribed
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public subscribe(eventType: string, callback: ((...args: any[]) => void) | null = null): void {
    this.subscribeToType(eventType, callback);
  }

  /**
   * Executes the provided event by emitting it to the registered handler
   * @param event The event to execute
   */
  public publish({ payload, type, id }: { payload: T; type: string; id?: string }): string {
    id = id || uuid.v4();
    this.publishMessage({
      data: {
        type,
        payload,
        id,
      },
    });

    return id;
  }
}

const eventBus = new EventBus();

export { eventBus };
