import { QueryClient } from '@tanstack/react-query';
import { getMutations } from './mutations';

class MutationProvider {
  public init = (queryClient: QueryClient): void => {
    const mutations = getMutations();
    mutations.forEach((mutation) => {
      queryClient.setMutationDefaults(mutation.mutationKey, {
        ...mutation,
      });
    });
  };
}

const mutationProvider = new MutationProvider();

export default mutationProvider;
