import { dispatch, store } from 'redux/store';
import { openDialog, closeDialog } from 'common/infrastructure/dialog/dialog.redux';

const DialogRepository = {
  openDialog: ({
    title,
    message,
    action,
    onClose,
  }: {
    title: string;
    message: string[] | string;
    action?: React.ReactNode;
    onClose?: VoidFunction;
  }) => {
    dispatch(openDialog({ title, message, action, onClose }));
  },

  closeDialog: () => {
    dispatch(closeDialog());
  },

  getDialogOpenState: () => {
    const { dialog: dialogStore } = store.getState();
    const { isOpen }: { isOpen: boolean } = dialogStore;

    return isOpen;
  },
};

export default DialogRepository;
