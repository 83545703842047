import { createSlice } from '@reduxjs/toolkit';

type IMapState = {
  center: number[] | null;
  zoom: number | null;
};

const initialState: IMapState = {
  center: null,
  zoom: null,
};

const slice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    saveCenter(state: IMapState, action: { payload: number[] }) {
      return {
        ...state,
        center: action.payload,
      };
    },
    saveZoom(state: IMapState, action: { payload: number }) {
      return {
        ...state,
        zoom: action.payload,
      };
    },
  },
});

const { reducer } = slice;

if (!reducer) {
  throw new Error('No reducer found for map');
}

// eslint-disable-next-line import/no-unused-modules
export { reducer, slice };
