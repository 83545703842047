import i18n from 'locales/i18n';
import { PATH_APP } from 'routes/paths';
import { externalLogout } from 'identityAndAccess/infrastructure/utils';
import FeatureEnablement from 'identityAndAccess/infrastructure/featureEnablement';

const navConfig = [
  {
    subheader: i18n.t('features'),
    // km 11/28/22: TODO, pass in tenant info for lookup (or have prepped) to enable/disable
    items: [
      {
        title: i18n.t('asset_class'),
        path: PATH_APP.inventory,
        iconifyIcon: 'bi:tree-fill',
        disabled: !FeatureEnablement.forTenant().inventory,
      },
      {
        title: i18n.t('work'),
        path: PATH_APP.work,
        iconifyIcon: 'fluent:wrench-16-filled',
        disabled: !FeatureEnablement.forTenant().work,
      },
      {
        title: i18n.t('inspections'),
        path: PATH_APP.inspections,
        iconifyIcon: 'fa6-solid:magnifying-glass',
        disabled: !FeatureEnablement.forTenant().inspections,
      },
    ],
  },
  {
    subheader: i18n.t('account'),
    items: [
      {
        title: i18n.t('logout'),
        path: '#',
        onClick: externalLogout,
        iconifyIcon: 'ic:outline-log-out',
      },
    ],
  },
  {
    subheader: i18n.t('support'),
    items: [
      {
        title: i18n.t('PlanIT Geo'),
        path: 'https://planitgeo.com/',
      },
      {
        title: i18n.t('Contact Us'),
        path: 'https://planitgeo.com/contact-us/',
      },
      {
        title: i18n.t('Support'),
        path: 'https://support.treeplotter.com/',
      },
    ],
  },
];

export default navConfig;
