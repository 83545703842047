import localForage from 'localforage';
import { BaseStorage, Lock } from './BaseStorage';

class LocalForage extends BaseStorage {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async getItem(key: string, lock?: Lock): Promise<any> {
    !lock && (await this.getLock(key));
    const item = await localForage.getItem(key);
    !lock && this.releaseLock(key);

    return item;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async setItem(key: string, value: any, lock?: Lock): Promise<void> {
    !lock && (await this.getLock(key));
    await localForage.setItem(key, value);
    !lock && this.releaseLock(key);
  }

  public async removeItem(key: string, lock?: Lock): Promise<void> {
    !lock && (await this.getLock(key));
    await localForage.removeItem(key);
    !lock && this.releaseLock(key);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getStorageInstance(): any {
    return localForage;
  }
}

const localForageInstance = new LocalForage();

export default localForageInstance;
