import { lazy } from 'react';
import { Loadable } from 'components/loading-screen/LoadingScreen';

export const HomePage = Loadable(lazy(() => import('identityAndAccess/presentation/HomePage')));

export const LoginPage = Loadable(
  lazy(() => import('identityAndAccess/presentation/login/LoginPage'))
);
export const AwsCognitoCallbackPage = Loadable(
  lazy(() => import('identityAndAccess/presentation/login/AwsCognitoCallbackPage'))
);

// TEMP DISABLE
// eslint-disable-next-line import/no-unused-modules
export const RegisterPage = Loadable(
  lazy(() => import('identityAndAccess/presentation/register/RegisterPage'))
);

// km 3/1/23: below pages are unused right now, keeping around because we will likely implement in the future
// eslint-disable-next-line import/no-unused-modules
export const VerifyCodePage = Loadable(
  lazy(() => import('identityAndAccess/presentation/register/VerifyCodePage'))
);
// eslint-disable-next-line import/no-unused-modules
export const NewPasswordPage = Loadable(
  lazy(() => import('identityAndAccess/presentation/passwordReset/NewPasswordPage'))
);
// eslint-disable-next-line import/no-unused-modules
export const ResetPasswordPage = Loadable(
  lazy(() => import('identityAndAccess/presentation/passwordReset/ResetPasswordPage'))
);

// eslint-disable-next-line import/no-unused-modules
export const PermissionDeniedPage = Loadable(
  lazy(() => import('identityAndAccess/presentation/PermissionDeniedPage'))
);

// eslint-disable-next-line import/no-unused-modules
export const Page500 = Loadable(lazy(() => import('identityAndAccess/presentation/Page500')));
// eslint-disable-next-line import/no-unused-modules
export const Page403 = Loadable(lazy(() => import('identityAndAccess/presentation/Page403')));
// eslint-disable-next-line import/no-unused-modules
export const Page404 = Loadable(lazy(() => import('identityAndAccess/presentation/Page404')));
// eslint-disable-next-line import/no-unused-modules
export const MaintenancePage = Loadable(
  lazy(() => import('identityAndAccess/presentation/MaintenancePage'))
);
