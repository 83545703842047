import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import storageLocal from 'common/infrastructure/storageLocal/LocalForage';
// slices
import loggingReducer from 'common/infrastructure/log/repository';
import { reducer as tenantReducer } from 'common/infrastructure/tenant/redux';
import { reducer as dialogReducer } from 'common/infrastructure/dialog/dialog.redux';
import { reducer as networkReducer } from 'common/infrastructure/network/network.redux';
import { reducer as usersReducer } from 'identityAndAccess/infrastructure/user/redux';
import { reducer as assetClassReducer } from 'assetClass/infrastructure/assetClass/redux';
import { reducer as mapReducer } from 'common/infrastructure/map/redux';
import { reducer as authReducer } from 'identityAndAccess/infrastructure/auth/redux';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  //storage: localForage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const tenantPersistConfig = {
  key: 'tenants',
  storage, //localForage,
  keyPrefix: 'redux-',
};

const usersPersistConfig = {
  key: 'users',
  storage, //localForage,
  keyPrefix: 'redux-',
};

const assetClassPersistConfig = {
  key: 'assetClasses',
  storage, //localForage,
  keyPrefix: 'redux-',
};

const loggingPersistConfig = {
  key: 'logging',
  storage: storageLocal.getStorageInstance(),
  keyPrefix: 'redux-',
};

const mapPersistConfig = {
  key: 'map',
  storage,
  keyPrefix: 'redux-',
};

const authPersistConfig = {
  key: 'auth',
  storage, //localForage,
  keyPrefix: 'redux-',
};

const rootReducer = combineReducers({
  logging: persistReducer(loggingPersistConfig, loggingReducer),
  tenants: persistReducer(tenantPersistConfig, tenantReducer),
  dialog: dialogReducer,
  network: networkReducer,
  users: persistReducer(usersPersistConfig, usersReducer),
  assetClasses: persistReducer(assetClassPersistConfig, assetClassReducer),
  map: persistReducer(mapPersistConfig, mapReducer),
  auth: persistReducer(authPersistConfig, authReducer),
});

export type RootStoreType = ReturnType<typeof rootReducer>;

export { rootPersistConfig, rootReducer };
