// TODO: Some of these should be dynamically created from variables in the build process
const regionalConfig = {
  local: {
    us: {
      tp_api: {
        api_base_url: 'http://lvh.me:8000',
      },
      tp3_api: {
        api_base_url: 'http://localhost:3000',
      },
      auth: {
        api_base_url: 'https://os0w896034.execute-api.us-west-2.amazonaws.com/dev',
        api_token_path: '/auth/token',
      },
      cognito: {
        base_url: 'https://auth.dev.pg-cloud.com',
        client_id: '6ufd2u9c3es52t9moljrn43jk1',
        redirect_base_url: 'https://os0w896034.execute-api.us-west-2.amazonaws.com/dev',
        redirect_client_url: 'https://mobile.staging.treeplotter.com',
      },
      mapbox: {
        access_token:
          'pk.eyJ1IjoibWljaGFlbGdlbnRyeSIsImEiOiJjbGF6ZDVnamQwa28xM29yenNpcHozNHFnIn0.qshj7mxQlDwMTJODlXS47A',
      },
      openlayers: {
        api_key:
          'AAPK7786a5843ad0433f86e7b805a29406441Y8w-tFYDNLAxvF7iplqKX9Wm5unJYkl-Z56ck7wbnjUNWwRMyD23Zy6j37iILcT',
      },
    },
  },
  dev: {
    us: {
      tp_api: {
        // temp!!! Ganymede is set up to ingest logs
        api_base_url: 'https://ganymede.pg-cloud.com/api',
      },
      tp3_api: {
        api_base_url: 'https://api.dev.us.treeplotter.com',
      },
      auth: {
        api_base_url: 'https://os0w896034.execute-api.us-west-2.amazonaws.com/dev',
        api_token_path: '/auth/token',
      },
      cognito: {
        base_url: 'https://auth.dev.pg-cloud.com',
        client_id: '6ufd2u9c3es52t9moljrn43jk1',
        redirect_base_url: 'https://os0w896034.execute-api.us-west-2.amazonaws.com/dev',
        redirect_client_url: 'https://app.dev.us.treeplotter.com',
      },
      mapbox: {
        access_token:
          'pk.eyJ1IjoibWljaGFlbGdlbnRyeSIsImEiOiJjbGF6ZDVnamQwa28xM29yenNpcHozNHFnIn0.qshj7mxQlDwMTJODlXS47A',
      },
      openlayers: {
        api_key:
          'AAPK7786a5843ad0433f86e7b805a29406441Y8w-tFYDNLAxvF7iplqKX9Wm5unJYkl-Z56ck7wbnjUNWwRMyD23Zy6j37iILcT',
      },
    },
  },
  staging: {
    us: {
      tp_api: {
        api_base_url: 'https://staging.pg-cloud.com/api',
      },
      tp3_api: {
        api_base_url: 'https://api.staging.us.treeplotter.com',
      },
      auth: {
        api_base_url: 'https://nhhygyyfuc.execute-api.us-west-2.amazonaws.com/stage',
        api_token_path: '/auth/token',
      },
      cognito: {
        base_url: 'https://auth.staging.pg-cloud.com',
        client_id: '57bfejlvlls7jcic5vr3s1m4e4',
        redirect_base_url: 'https://nhhygyyfuc.execute-api.us-west-2.amazonaws.com/stage',
        redirect_client_url: 'https://app.staging.us.treeplotter.com',
      },
      mapbox: {
        access_token:
          'pk.eyJ1IjoibWljaGFlbGdlbnRyeSIsImEiOiJjbGF6ZDVnamQwa28xM29yenNpcHozNHFnIn0.qshj7mxQlDwMTJODlXS47A',
      },
      openlayers: {
        api_key:
          'AAPK7786a5843ad0433f86e7b805a29406441Y8w-tFYDNLAxvF7iplqKX9Wm5unJYkl-Z56ck7wbnjUNWwRMyD23Zy6j37iILcT',
      },
    },
  },
  prod: {
    us: {
      tp_api: {
        api_base_url: 'https://pg-cloud.com/api',
      },
      tp3_api: {
        api_base_url: 'TODO',
      },
      auth: {
        api_base_url: 'https://ldznkzqxtl.execute-api.us-west-2.amazonaws.com/prod',
        api_token_path: '/auth/token',
      },
      cognito: {
        base_url: 'https://auth.pg-cloud.com',
        client_id: '66t83flkaecnt6i6dhv6sdahmm',
        redirect_base_url: 'https://ldznkzqxtl.execute-api.us-west-2.amazonaws.com/prod',
        redirect_client_url: 'https://mobile.us.treeplotter.com/auth/cognito-callback',
      },
      mapbox: {
        access_token:
          'pk.eyJ1IjoibWljaGFlbGdlbnRyeSIsImEiOiJjbGF6ZDVnamQwa28xM29yenNpcHozNHFnIn0.qshj7mxQlDwMTJODlXS47A',
      },
      openlayers: {
        api_key:
          'AAPK7786a5843ad0433f86e7b805a29406441Y8w-tFYDNLAxvF7iplqKX9Wm5unJYkl-Z56ck7wbnjUNWwRMyD23Zy6j37iILcT',
      },
    },
    ca: {
      tp_api: {
        api_base_url: 'https://ca.pg-cloud.com/api',
      },
      tp3_api: {
        api_base_url: 'TODO',
      },
      auth: {
        api_base_url: 'https://09pad15mcg.execute-api.ca-central-1.amazonaws.com/prod',
        api_token_path: '/auth/token',
      },
      cognito: {
        base_url: 'https://auth.ca.pg-cloud.com',
        client_id: '464dgbadm4hlfc42h7qkcjpsc3',
        redirect_base_url: 'https://09pad15mcg.execute-api.ca-central-1.amazonaws.com/prod',
        redirect_client_url: 'https://mobile.ca.treeplotter.com/auth/cognito-callback',
      },
      mapbox: {
        access_token:
          'pk.eyJ1IjoibWljaGFlbGdlbnRyeSIsImEiOiJjbGF6ZDVnamQwa28xM29yenNpcHozNHFnIn0.qshj7mxQlDwMTJODlXS47A',
      },
      openlayers: {
        api_key:
          'AAPK7786a5843ad0433f86e7b805a29406441Y8w-tFYDNLAxvF7iplqKX9Wm5unJYkl-Z56ck7wbnjUNWwRMyD23Zy6j37iILcT',
      },
    },
    uk: {
      tp_api: {
        api_base_url: 'https://uk.pg-cloud.com/api',
      },
      tp3_api: {
        api_base_url: 'TODO',
      },
      auth: {
        api_base_url: 'https://1u7gkfbf22.execute-api.eu-west-2.amazonaws.com/prod',
        api_token_path: '/auth/token',
      },
      cognito: {
        base_url: 'https://auth.uk.pg-cloud.com',
        client_id: '4pcr4b9oa89ln433vabcpke6sk',
        redirect_base_url: 'https://1u7gkfbf22.execute-api.eu-west-2.amazonaws.com/prod',
        redirect_client_url: 'https://mobile.uk.treeplotter.com/auth/cognito-callback',
      },
      mapbox: {
        access_token:
          'pk.eyJ1IjoibWljaGFlbGdlbnRyeSIsImEiOiJjbGF6ZDVnamQwa28xM29yenNpcHozNHFnIn0.qshj7mxQlDwMTJODlXS47A',
      },
      openlayers: {
        api_key:
          'AAPK7786a5843ad0433f86e7b805a29406441Y8w-tFYDNLAxvF7iplqKX9Wm5unJYkl-Z56ck7wbnjUNWwRMyD23Zy6j37iILcT',
      },
    },
    au: {
      tp_api: {
        api_base_url: 'https://au.pg-cloud.com/api',
      },
      tp3_api: {
        api_base_url: 'TODO',
      },
      auth: {
        api_base_url: 'https://e5iyk92msh.execute-api.ap-southeast-2.amazonaws.com/prod',
        api_token_path: '/auth/token',
      },
      cognito: {
        base_url: 'https://auth.au.pg-cloud.com',
        client_id: '3su0d85asf9ktuq7c6l89veefl',
        redirect_base_url: 'https://e5iyk92msh.execute-api.ap-southeast-2.amazonaws.com/prod',
        redirect_client_url: 'https://mobile.au.treeplotter.com/auth/cognito-callback',
      },
      mapbox: {
        access_token:
          'pk.eyJ1IjoibWljaGFlbGdlbnRyeSIsImEiOiJjbGF6ZDVnamQwa28xM29yenNpcHozNHFnIn0.qshj7mxQlDwMTJODlXS47A',
      },
      openlayers: {
        api_key:
          'AAPK7786a5843ad0433f86e7b805a29406441Y8w-tFYDNLAxvF7iplqKX9Wm5unJYkl-Z56ck7wbnjUNWwRMyD23Zy6j37iILcT',
      },
    },
  },
};

type Config = typeof regionalConfig;

export type Env = keyof Config;
export type Region = keyof Config[Env];

export default regionalConfig;
