/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TreePlotter v3 API
 * OpenAPI spec version: 0.6.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  CreateReferenceTableItem,
  CreateReferenceTableItem201,
  CreateReferenceTableItemParams,
  DeleteReferenceTableItemParams,
  GetReferenceTableItemParams,
  GetReferenceTableItemsParams,
  Id,
  ReferenceTableItem,
  UnauthorizedErrorResponse,
  UpdateReferenceTableItem,
  UpdateReferenceTableItemParams,
} from '.././model';
import { httpClient } from '../../../src/common/infrastructure/api/http-client';
import { queryMutator } from '../../../src/common/infrastructure/api/queryMutator';

/**
 * @summary Get reference table items
 */
export const getReferenceTableItems = (
  params: GetReferenceTableItemsParams,
  signal?: AbortSignal
) => {
  return httpClient<ReferenceTableItem[]>({
    url: `/reference-table-items`,
    method: 'GET',
    params,
    signal,
  });
};

export const getGetReferenceTableItemsQueryKey = (params: GetReferenceTableItemsParams) => {
  return [`/reference-table-items`, ...(params ? [params] : [])] as const;
};

export const useGetReferenceTableItemsQueryOptions = <
  TData = Awaited<ReturnType<typeof getReferenceTableItems>>,
  TError = void
>(
  params: GetReferenceTableItemsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getReferenceTableItems>>, TError, TData>;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReferenceTableItemsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReferenceTableItems>>> = ({ signal }) =>
    getReferenceTableItems(params, signal);

  const customOptions = queryMutator({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof getReferenceTableItems>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReferenceTableItemsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getReferenceTableItems>>
>;
export type GetReferenceTableItemsQueryError = void;

/**
 * @summary Get reference table items
 */
export const useGetReferenceTableItems = <
  TData = Awaited<ReturnType<typeof getReferenceTableItems>>,
  TError = void
>(
  params: GetReferenceTableItemsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getReferenceTableItems>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetReferenceTableItemsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get reference table item
 */
export const getReferenceTableItem = (
  referenceTableItemId: Id,
  params: GetReferenceTableItemParams,
  signal?: AbortSignal
) => {
  return httpClient<ReferenceTableItem>({
    url: `/reference-table-items/${referenceTableItemId}`,
    method: 'GET',
    params,
    signal,
  });
};

export const getGetReferenceTableItemQueryKey = (
  referenceTableItemId: Id,
  params: GetReferenceTableItemParams
) => {
  return [`/reference-table-items/${referenceTableItemId}`, ...(params ? [params] : [])] as const;
};

export const useGetReferenceTableItemQueryOptions = <
  TData = Awaited<ReturnType<typeof getReferenceTableItem>>,
  TError = void
>(
  referenceTableItemId: Id,
  params: GetReferenceTableItemParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getReferenceTableItem>>, TError, TData>;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetReferenceTableItemQueryKey(referenceTableItemId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReferenceTableItem>>> = ({ signal }) =>
    getReferenceTableItem(referenceTableItemId, params, signal);

  const customOptions = queryMutator({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof getReferenceTableItem>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReferenceTableItemQueryResult = NonNullable<
  Awaited<ReturnType<typeof getReferenceTableItem>>
>;
export type GetReferenceTableItemQueryError = void;

/**
 * @summary Get reference table item
 */
export const useGetReferenceTableItem = <
  TData = Awaited<ReturnType<typeof getReferenceTableItem>>,
  TError = void
>(
  referenceTableItemId: Id,
  params: GetReferenceTableItemParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getReferenceTableItem>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetReferenceTableItemQueryOptions(referenceTableItemId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create reference table item
 */
export const createReferenceTableItem = (
  createReferenceTableItem: CreateReferenceTableItem,
  params: CreateReferenceTableItemParams
) => {
  return httpClient<CreateReferenceTableItem201 | void>({
    url: `/reference-table-items/create`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createReferenceTableItem,
    params,
  });
};

export const getCreateReferenceTableItemMutationOptions = <
  TError = void | UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createReferenceTableItem>>,
    TError,
    { data: CreateReferenceTableItem; params: CreateReferenceTableItemParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createReferenceTableItem>>,
  TError,
  { data: CreateReferenceTableItem; params: CreateReferenceTableItemParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createReferenceTableItem>>,
    { data: CreateReferenceTableItem; params: CreateReferenceTableItemParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return createReferenceTableItem(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateReferenceTableItemMutationResult = NonNullable<
  Awaited<ReturnType<typeof createReferenceTableItem>>
>;
export type CreateReferenceTableItemMutationBody = CreateReferenceTableItem;
export type CreateReferenceTableItemMutationError = void | UnauthorizedErrorResponse;

/**
 * @summary Create reference table item
 */
export const useCreateReferenceTableItem = <
  TError = void | UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createReferenceTableItem>>,
    TError,
    { data: CreateReferenceTableItem; params: CreateReferenceTableItemParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createReferenceTableItem>>,
  TError,
  { data: CreateReferenceTableItem; params: CreateReferenceTableItemParams },
  TContext
> => {
  const mutationOptions = getCreateReferenceTableItemMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateReferenceTableItem = (
  referenceTableItemId: Id,
  updateReferenceTableItem: UpdateReferenceTableItem,
  params: UpdateReferenceTableItemParams
) => {
  return httpClient<void>({
    url: `/reference-table-items/${referenceTableItemId}/update`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: updateReferenceTableItem,
    params,
  });
};

export const getUpdateReferenceTableItemMutationOptions = <
  TError = void | UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateReferenceTableItem>>,
    TError,
    {
      referenceTableItemId: Id;
      data: UpdateReferenceTableItem;
      params: UpdateReferenceTableItemParams;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateReferenceTableItem>>,
  TError,
  {
    referenceTableItemId: Id;
    data: UpdateReferenceTableItem;
    params: UpdateReferenceTableItemParams;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateReferenceTableItem>>,
    {
      referenceTableItemId: Id;
      data: UpdateReferenceTableItem;
      params: UpdateReferenceTableItemParams;
    }
  > = (props) => {
    const { referenceTableItemId, data, params } = props ?? {};

    return updateReferenceTableItem(referenceTableItemId, data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateReferenceTableItemMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateReferenceTableItem>>
>;
export type UpdateReferenceTableItemMutationBody = UpdateReferenceTableItem;
export type UpdateReferenceTableItemMutationError = void | UnauthorizedErrorResponse;

export const useUpdateReferenceTableItem = <
  TError = void | UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateReferenceTableItem>>,
    TError,
    {
      referenceTableItemId: Id;
      data: UpdateReferenceTableItem;
      params: UpdateReferenceTableItemParams;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateReferenceTableItem>>,
  TError,
  {
    referenceTableItemId: Id;
    data: UpdateReferenceTableItem;
    params: UpdateReferenceTableItemParams;
  },
  TContext
> => {
  const mutationOptions = getUpdateReferenceTableItemMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Delete reference table item
 */
export const deleteReferenceTableItem = (
  referenceTableItemId: Id,
  params: DeleteReferenceTableItemParams
) => {
  return httpClient<void>({
    url: `/reference-table-items/${referenceTableItemId}/delete`,
    method: 'POST',
    params,
  });
};

export const getDeleteReferenceTableItemMutationOptions = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReferenceTableItem>>,
    TError,
    { referenceTableItemId: Id; params: DeleteReferenceTableItemParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteReferenceTableItem>>,
  TError,
  { referenceTableItemId: Id; params: DeleteReferenceTableItemParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteReferenceTableItem>>,
    { referenceTableItemId: Id; params: DeleteReferenceTableItemParams }
  > = (props) => {
    const { referenceTableItemId, params } = props ?? {};

    return deleteReferenceTableItem(referenceTableItemId, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteReferenceTableItemMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteReferenceTableItem>>
>;

export type DeleteReferenceTableItemMutationError = void;

/**
 * @summary Delete reference table item
 */
export const useDeleteReferenceTableItem = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReferenceTableItem>>,
    TError,
    { referenceTableItemId: Id; params: DeleteReferenceTableItemParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteReferenceTableItem>>,
  TError,
  { referenceTableItemId: Id; params: DeleteReferenceTableItemParams },
  TContext
> => {
  const mutationOptions = getDeleteReferenceTableItemMutationOptions(options);

  return useMutation(mutationOptions);
};
