/* eslint-disable no-unused-vars */
import { Mapper } from 'common/infrastructure/mapper';
import { AssetClassEntity } from '@planitgeo/treeplotter-shared/lib/modules/asset-class/asset-class/domain/asset-class.model';
import { AssetClassTag } from '@planitgeo/treeplotter-shared/lib/modules/asset-class/asset-class/domain/asset-class.interface';
import { AssetClass } from 'api-client';

const fromDTO = (data: AssetClass): AssetClassEntity =>
  new AssetClassEntity({
    id: data.id,
    tenantId: data.tenantId,
    name: data.name,
    tag: data.tag as AssetClassTag,
  });

const notImplemented = () => {
  throw new Error('Not implemented');
};

// there is "to/from store" and "from geojson"
export const AssetClassMapper: Mapper<AssetClassEntity, AssetClass> = {
  toDTO: notImplemented,
  fromDTO,
  toPersistence: notImplemented,
  fromPersistence: notImplemented,
};
