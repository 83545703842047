/* eslint-disable no-unused-vars */
import { Mapper } from 'common/infrastructure/mapper';
import {
  DataType,
  FieldLengthMeasurementUnit,
  FieldReferenceType,
  FieldTag,
  InputType,
} from '@planitgeo/treeplotter-shared/lib/modules/field/field/domain/field.interface';
import { FieldEntity } from '@planitgeo/treeplotter-shared/lib/modules/field/field/domain/field.model';
import { Field } from 'api-client';

const fromDTO = (data: Field): FieldEntity =>
  new FieldEntity({
    id: data.id,
    referenceId: data.referenceId,
    referenceType: data.referenceType as FieldReferenceType,
    name: data.name,
    description: data.description,
    inputType: data.inputType as InputType,
    dataType: data.dataType as DataType,
    allowMultipleValues: data.allowMultipleValues,
    referenceTableFieldId: data.referenceTableFieldId,
    tag: data.tag as FieldTag,
    orderIndex: data.orderIndex,
    orderIndexInventoryMode: data.orderIndexInventoryMode,
    lengthMeasurementUnit: data.lengthMeasurementUnit as FieldLengthMeasurementUnit,
    defaultValue: data.defaultValue,
    required: data.required,
    readOnly: data.readOnly,
    immutable: data.immutable,
    maxLength: data.maxLength,
    shouldTriggerAutoUpdate: data.shouldTriggerAutoUpdate,
    syncFieldUuids: data.syncFieldUuids,
  });

const notImplemented = () => {
  throw new Error('Not implemented');
};

export const FieldMapper: Mapper<FieldEntity, Field> = {
  toDTO: notImplemented,
  fromDTO,
  toPersistence: notImplemented,
  fromPersistence: notImplemented,
};
