import { LoadingButton } from '@mui/lab';
import { useLocales } from 'locales';
import { getLoginUrl } from '../../infrastructure/utils';
import { useAuthContext } from '../../infrastructure/useAuthContext';

export default function AuthLoginForm() {
  const { translate } = useLocales();
  const { login } = useAuthContext();

  const onClick = async () => {
    if (process.env.REACT_APP_ENV === 'local' && process.env.REACT_APP_TOKEN) {
      const idToken = process.env.REACT_APP_TOKEN;
      await login(idToken);
    } else {
      window.location.href = getLoginUrl();
    }
  };

  return (
    <LoadingButton
      fullWidth
      color="inherit"
      size="large"
      type="submit"
      variant="contained"
      sx={{
        bgcolor: 'text.primary',
        color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
        '&:hover': {
          bgcolor: 'text.primary',
          color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
        },
      }}
      onClick={onClick}
    >
      {translate('auth.login')}
    </LoadingButton>
  );
}
