/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TreePlotter v3 API
 * OpenAPI spec version: 0.6.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { Field, GetFieldsParams, Id } from '.././model';
import { httpClient } from '../../../src/common/infrastructure/api/http-client';
import { queryMutator } from '../../../src/common/infrastructure/api/queryMutator';

export const getFields = (params: GetFieldsParams, signal?: AbortSignal) => {
  return httpClient<Field[]>({ url: `/fields`, method: 'GET', params, signal });
};

export const getGetFieldsQueryKey = (params: GetFieldsParams) => {
  return [`/fields`, ...(params ? [params] : [])] as const;
};

export const useGetFieldsQueryOptions = <
  TData = Awaited<ReturnType<typeof getFields>>,
  TError = void
>(
  params: GetFieldsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getFields>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFieldsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFields>>> = ({ signal }) =>
    getFields(params, signal);

  const customOptions = queryMutator({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<Awaited<ReturnType<typeof getFields>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetFieldsQueryResult = NonNullable<Awaited<ReturnType<typeof getFields>>>;
export type GetFieldsQueryError = void;

export const useGetFields = <TData = Awaited<ReturnType<typeof getFields>>, TError = void>(
  params: GetFieldsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getFields>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetFieldsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getField = (fieldId: Id, signal?: AbortSignal) => {
  return httpClient<Field>({ url: `/fields/${fieldId}`, method: 'GET', signal });
};

export const getGetFieldQueryKey = (fieldId: Id) => {
  return [`/fields/${fieldId}`] as const;
};

export const useGetFieldQueryOptions = <
  TData = Awaited<ReturnType<typeof getField>>,
  TError = void
>(
  fieldId: Id,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getField>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFieldQueryKey(fieldId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getField>>> = ({ signal }) =>
    getField(fieldId, signal);

  const customOptions = queryMutator({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<Awaited<ReturnType<typeof getField>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetFieldQueryResult = NonNullable<Awaited<ReturnType<typeof getField>>>;
export type GetFieldQueryError = void;

export const useGetField = <TData = Awaited<ReturnType<typeof getField>>, TError = void>(
  fieldId: Id,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getField>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetFieldQueryOptions(fieldId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Delete field
 */
export const deleteField = (fieldId: Id) => {
  return httpClient<void>({ url: `/fields/${fieldId}/delete`, method: 'POST' });
};

export const getDeleteFieldMutationOptions = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteField>>,
    TError,
    { fieldId: Id },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteField>>,
  TError,
  { fieldId: Id },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteField>>, { fieldId: Id }> = (
    props
  ) => {
    const { fieldId } = props ?? {};

    return deleteField(fieldId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteFieldMutationResult = NonNullable<Awaited<ReturnType<typeof deleteField>>>;

export type DeleteFieldMutationError = void;

/**
 * @summary Delete field
 */
export const useDeleteField = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteField>>,
    TError,
    { fieldId: Id },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteField>>,
  TError,
  { fieldId: Id },
  TContext
> => {
  const mutationOptions = getDeleteFieldMutationOptions(options);

  return useMutation(mutationOptions);
};
