import { Message } from 'common/infrastructure/bus';
import { ICommand } from 'common/infrastructure/command.bus';
import { CreateAssetCommand } from '@planitgeo/treeplotter-shared/lib/modules/asset-class/asset/domain/command/create-asset.command';
import { AssetEntity } from '@planitgeo/treeplotter-shared/lib/modules/asset-class/asset/domain/asset.model';
import FieldRepository from 'assetClass/infrastructure/field/repository';
import { eventBus } from 'common/infrastructure/event.bus';
import AssetRepository from 'assetClass/infrastructure/assetClass/asset/repository';

export const processCommand = async (command: Message<ICommand<CreateAssetCommand>>) => {
  const { externalId, assetClassId, attributes } = command.data.payload.asset;

  const fields = await FieldRepository.getFields(assetClassId);
  const asset = new AssetEntity(
    {
      id: externalId as string,
      assetClassId: assetClassId,
      attributes: attributes,
    },
    fields
  );

  await AssetRepository.addAssetToLocalState(asset);

  eventBus.publish({
    type: 'AssetCreatedEvent',
    payload: asset.create(),
  });
};
