import { useMemo } from 'react';
import { QueryClient } from '@tanstack/react-query';
import {
  getGetReferenceTableItemsQueryKey,
  useGetReferenceTableItem,
  useGetReferenceTableItems,
  getReferenceTableItems,
} from 'api-client';
import { ReferenceTableItemMapper } from './mapper';
import { transformQueryKeyPathToArray } from 'common/infrastructure/api/queryMutator';
import { queryClient as moduleQueryClient } from 'common/infrastructure/remoteState/persistQueryClient';

const ReferenceTableItemRepository = {
  useReferenceTableItems(referenceTableId = '') {
    const useQueryResponse = useGetReferenceTableItems(
      { referenceTableId },
      {
        query: {
          enabled: !!referenceTableId,
        },
      }
    );

    const referenceTableItemEntities = useMemo(() => {
      if (!useQueryResponse?.data || !Array.isArray(useQueryResponse.data)) {
        return [];
      }
      return useQueryResponse.data.map(ReferenceTableItemMapper.fromDTO);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(useQueryResponse?.data)]);

    return {
      ...useQueryResponse,
      referenceTableItemEntities,
    };
  },

  useReferenceTableItem(referenceTableId: string, referenceTableRecordId: string) {
    const useQueryResponse = useGetReferenceTableItem(referenceTableRecordId, { referenceTableId });

    const referenceTableItemEntity = useMemo(() => {
      if (!useQueryResponse?.data) {
        return null;
      }
      return ReferenceTableItemMapper.fromDTO(useQueryResponse.data);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(useQueryResponse?.data)]);

    return {
      ...useQueryResponse,
      referenceTableItemEntity,
    };
  },

  async prefetchReferenceTableItems(referenceTableId: string) {
    const queryKey = transformQueryKeyPathToArray(
      getGetReferenceTableItemsQueryKey({ referenceTableId })
    );
    await moduleQueryClient.prefetchQuery({
      queryKey,
      queryFn: () => getReferenceTableItems({ referenceTableId }),
    });
  },

  async refetchQueries(queryClient: QueryClient, referenceTableId: string) {
    const getReferenceTableItemsQueryKey = transformQueryKeyPathToArray(
      getGetReferenceTableItemsQueryKey({ referenceTableId })
    );

    await queryClient.cancelQueries(getReferenceTableItemsQueryKey);
    await queryClient.invalidateQueries(getReferenceTableItemsQueryKey);
  },
};

export default ReferenceTableItemRepository;
