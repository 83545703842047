import { Message } from 'common/infrastructure/bus';
import { ICommand } from 'common/infrastructure/command.bus';
import { FieldSyncUpdateAssetCommand } from '@planitgeo/treeplotter-shared/lib/modules/asset-class/asset/domain/command/field-sync-update-asset.command';
import { AssetEntity } from '@planitgeo/treeplotter-shared/lib/modules/asset-class/asset/domain/asset.model';
import FieldRepository from 'assetClass/infrastructure/field/repository';
import { eventBus } from 'common/infrastructure/event.bus';

export const processCommand = async (command: Message<ICommand<FieldSyncUpdateAssetCommand>>) => {
  const { id: assetId, assetClassId, attributes } = command.data.payload.asset;

  const fields = await FieldRepository.getFields(assetClassId);
  const asset = new AssetEntity(
    {
      id: assetId,
      assetClassId: assetClassId,
      attributes,
    },
    fields
  );

  eventBus.publish({
    type: 'FieldSyncAssetUpdatedEvent',
    payload: asset.fieldSyncUpdate({
      ...attributes,
    }),
  });
};
