const en = {
  auth: {
    email: `Email`,
    password: `Password`,
    login: `Login`,
    logout: `Logout`,
    create_an_account: `Create an account?`,
    forgot_password: `Forgot password?`,
  },
  login: {
    title: `Sign in to TreePlotter Mobile`,
    greeting: `Hi, welcome back`,
    new_user: `New user?`,
  },
  app: `app`,
  user: `user`,
  list: `list`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level: `menu level`,
  menu_level_2a: `menu level 2a`,
  menu_level_2b: `menu level 2b`,
  menu_level_3a: `menu level 3a`,
  menu_level_3b: `menu level 3b`,
  menu_level_4a: `menu level 4a`,
  menu_level_4b: `menu level 4b`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  item_external_link: `item external link`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
  // tp-mobile
  home: `home`,
  asset_class: `Inventory`,
  asset_class_with_tenant: `Inventory | {{tenant}}`,
  work: `work`,
  inspections: `inspections`,
  greeting_with_name: `Let's plot some trees!`, // TODO: Put back the user's first name
  account: `account`,
  tenant: 'tenant',
  tenant_select_ui: 'Select Client',
  tenant_list_ui: 'Clients',
  no_tenant_found: 'No client found',
  no_tenant_found_error: 'No client was found. This is an error, please contact support below.',
  error: `Error`,
  error_detected: `We've detected an error in our system.`,
  contact_support_if_persists: 'Please contact support if the issue persists.',
  error_details_below: `You can view details about the specific error below:`,
  contact_support: 'Contact Support',
  logout: `logout`,
  forgot_password: `forgot password`,
  features: `features`,
  are_you_sure_proceed: `Are you sure you want to proceed?`,
  settings: `Settings`,
  application_name_version: `TreePlotter MOBILE Version`,
  data: {
    import: `Import`,
    import_assets_description: `This action will temporarily remove data from the map, before importing all synced data from TreePlotter INVENTORY. All data added in TreePlotter MOBILE is already in TreePlotter INVENTORY and will re-appear on the map once the sync is completed.`,
    import_assets_description_2: `Please note that this process may cause sync delays for data added during the import and will affect all users.`,
    assets_import_started: `Asset Import Started`,
    assets_import_failed: `Asset Import Failed`,
    save: `Save`,
    edit: `Edit`,
    sync: `Sync`,
    sync_assets: `Sync Assets`,
    bulk_sync_treeplotter: `Bulk Sync Assets from TreePlotter`,
    edit_item: `{{layerName}} Details`,
    view_item: `{{layerName}} Details`,
    create_new_item: `New {{layerName}} `,
    ignore_changes: `Ignore Changes`,
    ignore_changes_prompt: `You have chosen to exit the form without saving changes. Are you sure?`,
    continue: `Continue`,
    delete: `Delete`,
    delete_prompt: `Are you sure you wish to remove the selected {{item}}?`,
  },
  reset: `Reset`,
  cancel: `Cancel`,
  yes: `Yes`,
  no: `No`,
  field: {
    inventory_field_configuration_issue: `We have detected an issue with your Inventory's Field Configuration. You must refresh your page to continue inventorying.`,
  },
};

export default en;
