import { ReactNode } from 'react';
import { Box } from '@mui/material';

type LayoutBoxProps = {
  children: ReactNode;
};

export default function LayoutBox({ children }: LayoutBoxProps) {
  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      {children}
    </Box>
  );
}
