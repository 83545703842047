/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TreePlotter v3 API
 * OpenAPI spec version: 0.6.0
 */
import { useQuery } from '@tanstack/react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { AssetClass, GetAssetClassesParams, Id, UnauthorizedErrorResponse } from '.././model';
import { httpClient } from '../../../src/common/infrastructure/api/http-client';
import { queryMutator } from '../../../src/common/infrastructure/api/queryMutator';

/**
 * Fetch asset classes if user in JWT is authorized to access it.
 * @summary Get asset classes
 */
export const getAssetClasses = (params: GetAssetClassesParams, signal?: AbortSignal) => {
  return httpClient<AssetClass[]>({ url: `/asset-classes`, method: 'GET', params, signal });
};

export const getGetAssetClassesQueryKey = (params: GetAssetClassesParams) => {
  return [`/asset-classes`, ...(params ? [params] : [])] as const;
};

export const useGetAssetClassesQueryOptions = <
  TData = Awaited<ReturnType<typeof getAssetClasses>>,
  TError = UnauthorizedErrorResponse
>(
  params: GetAssetClassesParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getAssetClasses>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAssetClassesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAssetClasses>>> = ({ signal }) =>
    getAssetClasses(params, signal);

  const customOptions = queryMutator({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof getAssetClasses>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAssetClassesQueryResult = NonNullable<Awaited<ReturnType<typeof getAssetClasses>>>;
export type GetAssetClassesQueryError = UnauthorizedErrorResponse;

/**
 * @summary Get asset classes
 */
export const useGetAssetClasses = <
  TData = Awaited<ReturnType<typeof getAssetClasses>>,
  TError = UnauthorizedErrorResponse
>(
  params: GetAssetClassesParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getAssetClasses>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetAssetClassesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getAssetClass = (assetClassId: Id, signal?: AbortSignal) => {
  return httpClient<AssetClass>({ url: `/asset-classes/${assetClassId}`, method: 'GET', signal });
};

export const getGetAssetClassQueryKey = (assetClassId: Id) => {
  return [`/asset-classes/${assetClassId}`] as const;
};

export const useGetAssetClassQueryOptions = <
  TData = Awaited<ReturnType<typeof getAssetClass>>,
  TError = UnauthorizedErrorResponse | void
>(
  assetClassId: Id,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getAssetClass>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAssetClassQueryKey(assetClassId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAssetClass>>> = ({ signal }) =>
    getAssetClass(assetClassId, signal);

  const customOptions = queryMutator({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof getAssetClass>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAssetClassQueryResult = NonNullable<Awaited<ReturnType<typeof getAssetClass>>>;
export type GetAssetClassQueryError = UnauthorizedErrorResponse | void;

export const useGetAssetClass = <
  TData = Awaited<ReturnType<typeof getAssetClass>>,
  TError = UnauthorizedErrorResponse | void
>(
  assetClassId: Id,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getAssetClass>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetAssetClassQueryOptions(assetClassId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
