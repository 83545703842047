import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Button, IconButton, Stack, AppBar, Toolbar, Typography } from '@mui/material';
import { bgBlur } from 'utils/cssStyles';
import { useLocales } from 'locales';
import { HEADER } from 'config';
import { PATH_APP } from 'routes/paths';
import DialogRepository from 'common/infrastructure/dialog/dialog.repository';
import Iconify from 'common/presentation/iconify';

type Props = {
  title?: string;
  beforeMockModalCloseHandler?: () => boolean;
};

export default function ModalHeader({ title = '', beforeMockModalCloseHandler }: Props) {
  const navigate = useNavigate();
  const { translate } = useLocales();

  const theme = useTheme();

  const handleClose = () => {
    const continueClose = beforeMockModalCloseHandler ? beforeMockModalCloseHandler() : true;
    if (continueClose) {
      navigate(PATH_APP.inventory);
    } else {
      DialogRepository.openDialog({
        title: translate('data.ignore_changes'),
        message: translate('data.ignore_changes_prompt'),
        action: (
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              DialogRepository.closeDialog();
              navigate(PATH_APP.inventory);
            }}
          >
            {translate('data.continue')}
          </Button>
        ),
        onClose: DialogRepository.closeDialog,
      });
    }
  };

  const renderContent = (
    <>
      <Typography
        variant="h4"
        sx={{
          color: 'text.primary',
          flexGrow: 1,
        }}
      >
        {title}
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1.5 }}
      >
        <IconButton onClick={handleClose}>
          <Iconify icon="eva:close-fill" />
        </IconButton>
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        borderBottom: 1,
        borderColor: 'divider',
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
