import { createSlice } from '@reduxjs/toolkit';

type IDialogState = {
  isOpen: boolean;
  title: string;
  message: string | string[];
  action?: React.ReactNode;
  onClose?: VoidFunction;
};

const initialState: IDialogState = {
  isOpen: false,
  title: '',
  message: '',
  action: undefined,
  onClose: undefined,
};

const slice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    openDialog(
      state: IDialogState,
      action: {
        payload: {
          title: string;
          message: string | string[];
          action?: React.ReactNode;
          onClose?: VoidFunction;
        };
      }
    ) {
      const { title, message, action: dialogAction, onClose } = action.payload;
      state.isOpen = true;
      state.title = title;
      state.action = dialogAction;
      state.onClose = onClose;
      state.message = message;
    },
    closeDialog(state: IDialogState) {
      state.isOpen = false;
      state.title = '';
      state.message = '';
      state.action = undefined;
      state.onClose = undefined;
    },
  },
});

const { reducer } = slice;
const { openDialog, closeDialog } = slice.actions;

if (!reducer) {
  throw new Error('No reducer found for dialog');
}

// eslint-disable-next-line import/no-unused-modules
export { reducer, slice, openDialog, closeDialog };
