import jwtDecode from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { createContext, useEffect, useCallback } from 'react';
import { AuthIdToken, AuthUserType, AwsCognitoJwtContextType } from './types';
import { externalLogout, isTokenExpirationValid, refreshToken, setSession } from './utils';
import { getUser, importUser } from 'api-client';
import TenantRepository from 'common/infrastructure/tenant/repository';
import { queryClient } from 'common/infrastructure/remoteState/persistQueryClient';
import loggerService, { LoggerLevel } from 'common/infrastructure/log';
import { setAuth, AuthPayloadTypes } from './auth/redux';
import { RootStoreType } from 'redux/rootReducer';
import AssetClassRepository from 'assetClass/infrastructure/assetClass/repository';

export const AuthContext = createContext<AwsCognitoJwtContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const dispatch = useDispatch();
  const authState = useSelector((reduxState) => (reduxState as RootStoreType).auth);
  const tenantState = useSelector((reduxState) => (reduxState as RootStoreType).tenants);

  const initialize = useCallback(async () => {
    try {
      let idToken = typeof window !== 'undefined' ? localStorage.getItem('idToken') : '';

      if (idToken && !isTokenExpirationValid(idToken)) {
        idToken = await refreshToken(idToken);
      }

      if (idToken && isTokenExpirationValid(idToken)) {
        setSession(idToken);

        const decodedIdToken = jwtDecode<AuthIdToken>(idToken);

        const user = {
          username: decodedIdToken.username,
          sub: decodedIdToken.sub,
        };

        // invalidate tenant request
        await TenantRepository.refetchTenants(queryClient);
        if (tenantState.currentTenantPid) {
          await AssetClassRepository.refetchAssetClasses(tenantState.currentTenantPid);
        }

        dispatch(
          setAuth({
            type: AuthPayloadTypes.INITIAL,
            data: {
              isAuthenticated: true,
              user,
            },
          })
        );
      } else {
        dispatch(
          setAuth({
            type: AuthPayloadTypes.INITIAL,
            data: {
              isAuthenticated: false,
              user: null,
            },
          })
        );
      }
    } catch (error) {
      loggerService.log(LoggerLevel.ERROR, 'Error during initialization', error);
      dispatch(
        setAuth({
          type: AuthPayloadTypes.INITIAL,
          data: {
            isAuthenticated: false,
            user: null,
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    initialize();
  }, [initialize]);

  const login = async (idToken: string) => {
    setSession(idToken);

    const decodedIdToken = jwtDecode<AuthIdToken>(idToken);

    try {
      await importUser({
        id: decodedIdToken.sub,
      });

      // TODO: replace with websocket
      await new Promise((resolve) => setTimeout(resolve, 3000));

      const user = await getUser(decodedIdToken.sub);
      const { email } = user;

      const userData: AuthUserType = {
        displayName: 'PlanIt Geo User',
        email,
        role: 'admin',
        username: decodedIdToken.username,
        sub: decodedIdToken.sub,
      };

      // invalidate tenant request
      await TenantRepository.refetchTenants(queryClient);

      if (tenantState.currentTenantPid) {
        await AssetClassRepository.refetchAssetClasses(tenantState.currentTenantPid);
      }

      dispatch(
        setAuth({
          type: AuthPayloadTypes.LOGIN,
          data: {
            user: userData,
          },
        })
      );
    } catch (error) {
      loggerService.log(LoggerLevel.ERROR, "Couldn't log in", error);
      return;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const register = async (data: any) => {
    // TODO: implement register
  };

  const logout = async () => {
    dispatch(
      setAuth({
        type: AuthPayloadTypes.LOGOUT,
      })
    );
    await externalLogout();
  };

  return (
    <AuthContext.Provider
      value={{
        ...authState,
        method: 'awsCognitoJwt',
        register,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
