import { dispatch, store } from 'redux/store';
import { AssetClassMapper } from './mapper';
import {
  getGetAssetClassesQueryKey,
  useGetAssetClass,
  useGetAssetClasses,
  getAssetClasses,
  useImportAssets as useApiClientImportAssets,
  getImportAssetsMutationOptions,
} from 'api-client';
import { AssetClassEntity } from '@planitgeo/treeplotter-shared/lib/modules/asset-class/asset-class/domain/asset-class.model';
import {
  MutationOptions,
  defaultMutationOptions,
} from 'common/infrastructure/remoteState/mutations.interface';
import { saveCurrent } from 'assetClass/infrastructure/assetClass/redux';
import { setAssetsFetch } from 'common/infrastructure/network/network.redux';
import { queryClient as moduleQueryClient } from 'common/infrastructure/remoteState/persistQueryClient';
import { AxiosError } from 'axios';
import loggerService, { LoggerLevel } from 'common/infrastructure/log';
import { transformQueryKeyPathToArray } from 'common/infrastructure/api/queryMutator';
import FieldRepository from '../field/repository';
import { useSnackbar } from 'notistack';
import i18n from 'locales/i18n';
import { useMemo } from 'react';

const AssetClassRepository = {
  useGetAssetClass(assetClassId: string) {
    const { auth } = store.getState();

    const useQueryResponse = useGetAssetClass(assetClassId, {
      query: {
        enabled: auth.isAuthenticated && !!auth.user,
      },
    });

    const assetClassEntity = useMemo(() => {
      if (!useQueryResponse?.data) {
        return null;
      }
      return AssetClassMapper.fromDTO(useQueryResponse.data);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(useQueryResponse?.data)]);

    return {
      ...useQueryResponse,
      assetClassEntity,
    };
  },

  useGetAssetClasses(tenantId: string | undefined) {
    const { auth } = store.getState();

    const useQueryResponse = useGetAssetClasses(
      {
        tenantId: tenantId || '',
      },
      {
        query: {
          enabled: !!tenantId && auth.isAuthenticated && !!auth.user,
        },
      }
    );

    const assetClassEntities = useMemo(() => {
      if (!useQueryResponse?.data || !Array.isArray(useQueryResponse.data)) {
        return [];
      }
      return useQueryResponse.data.map(AssetClassMapper.fromDTO);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(useQueryResponse?.data)]);

    if (useQueryResponse?.failureReason && tenantId) {
      const axiosResponse = useQueryResponse?.failureReason as AxiosError;
      const status = axiosResponse.response?.status;
      if (status === 404) {
        this.refetchAssetClasses(tenantId).catch((err) => {
          loggerService.log(LoggerLevel.ERROR, err);
        });
        return {
          ...useQueryResponse,
          assetClassEntities: [],
        };
      }
    }

    return {
      ...useQueryResponse,
      assetClassEntities,
    };
  },

  getAssetFetchStatus() {
    // This doesn't require hooks
    const { network: networkStore } = store.getState();
    const { assetFetch }: { assetFetch: boolean } = networkStore;

    return assetFetch;
  },

  useCurrentAssetClass(tenantId: string | undefined) {
    const { assetClassEntities, ...useQueryResponse } =
      AssetClassRepository.useGetAssetClasses(tenantId);

    const { assetClasses: assetClassStore } = store.getState();
    const { currentAssetClassId }: { currentAssetClassId: string } = assetClassStore;

    if (!assetClassEntities.length) {
      return { assetClass: null, useQueryResponse };
    }

    let assetClass = assetClassEntities.find((t: AssetClassEntity) => t.id === currentAssetClassId);
    if (!assetClass) {
      assetClass = assetClassEntities[0];
      this.saveCurrent(assetClassEntities[0]?.id);
    }

    return {
      useQueryResponse,
      assetClass,
    };
  },

  useImportAssets() {
    const { enqueueSnackbar } = useSnackbar();
    let message = '';
    return useApiClientImportAssets({
      mutation: {
        ...(getImportAssetsMutationOptions({
          mutation: {
            ...defaultMutationOptions,
            mutationKey: ['importAssets'],
            onSuccess: () => {
              message = i18n.t('data.assets_import_started');
              enqueueSnackbar(message);
            },
            onError: () => {
              message = i18n.t('data.assets_import_failed');
              enqueueSnackbar(message, { variant: 'error' });
            },
          },
        }) as MutationOptions),
      },
    });
  },

  saveCurrent(assetClassId: string) {
    dispatch(saveCurrent(assetClassId));

    const { auth } = store.getState();
    if (auth.isAuthenticated) {
      this.preloadData(assetClassId);
    }
  },

  setAssetsFetch(assetFetch: boolean) {
    // This will tell react-query to either enable or disable the assetclass/assets fetch
    dispatch(setAssetsFetch(assetFetch));
  },

  preloadData(assetClassId: string) {
    FieldRepository.prefetchFields(assetClassId).catch((error) => {
      loggerService.log(LoggerLevel.ERROR, error);
    });
  },

  async prefetchAssetClasses(tenantId: string) {
    const queryKey = transformQueryKeyPathToArray(getGetAssetClassesQueryKey({ tenantId }));
    await moduleQueryClient.prefetchQuery({
      queryKey,
      queryFn: () => getAssetClasses({ tenantId }),
    });
  },

  async refetchAssetClasses(tenantId: string) {
    if (!tenantId) {
      return;
    }

    const queryKey = transformQueryKeyPathToArray(
      getGetAssetClassesQueryKey({
        tenantId,
      })
    );
    await moduleQueryClient.refetchQueries({ queryKey });
  },
};

export default AssetClassRepository;
