import { useMemo } from 'react';
import i18n from 'locales/i18n';
import { capitalCase } from 'change-case';
import { QueryClient } from '@tanstack/react-query';
import {
  Asset,
  CreateAsset,
  UpdateAsset,
  getGetAssetQueryKey,
  getGetAssetsQueryKey,
  getGetTenantByIdQueryKey,
  getCreateAssetMutationOptions,
  getUpdateAssetMutationOptions,
  useCreateAsset as useApiClientCreateAsset,
  useUpdateAsset as useApiClientUpdateAsset,
  useGetAsset,
  useGetAssets,
  getAssets,
  getAsset as getAssetFromApi,
} from 'api-client';
import TenantRepository from 'common/infrastructure/tenant/repository';
import FieldRepository from '../../field/repository';
import { AssetMapper } from './mapper';
import {
  MutationOptions,
  defaultMutationOptions,
} from 'common/infrastructure/remoteState/mutations.interface';
import { transformQueryKeyPathToArray } from 'common/infrastructure/api/queryMutator';
import AssetClassRepository from '../repository';
import DialogRepository from 'common/infrastructure/dialog/dialog.repository';
import { AxiosError } from 'axios';
import { queryClient as moduleQueryClient } from 'common/infrastructure/remoteState/persistQueryClient';
import loggerService, { LoggerLevel } from 'common/infrastructure/log';
import { CreateAssetCommand } from '@planitgeo/treeplotter-shared/lib/modules/asset-class/asset/domain/command/create-asset.command';
import { UpdateAssetCommand } from '@planitgeo/treeplotter-shared/lib/modules/asset-class/asset/domain/command/update-asset.command';
import { AssetEntity } from '@planitgeo/treeplotter-shared/lib/modules/asset-class/asset/domain/asset.model';
import { FieldEntity } from '@planitgeo/treeplotter-shared/lib/modules/field/field/domain/field.model';
import { BoundingBox } from '@planitgeo/treeplotter-shared/lib/infrastructure/geo/geo.interface';
import { commandBus } from 'common/infrastructure/command.bus';
import {
  ResetButton,
  resetFieldConfiguration,
} from 'assetClass/presentation/resetFieldConfiguration';

type CreateMutationContext = {
  previousAssets: Asset[];
};

// type UpdateMutationContext = {
//   previousAsset: Asset;
//   previousAssets: Asset[];
// };

const AssetRepository = {
  useAssets(
    assetClassId: string,
    options: {
      boundingBox?: BoundingBox;
    } = {}
  ) {
    const { boundingBox } = options;
    const { fieldEntities } = FieldRepository.useGetFields(assetClassId);
    const useQueryResponse = useGetAssets(
      {
        minLng: boundingBox?.minLng,
        minLat: boundingBox?.minLat,
        maxLng: boundingBox?.maxLng,
        maxLat: boundingBox?.maxLat,
        assetClassId,
      },
      {
        query: {
          enabled: AssetClassRepository.getAssetFetchStatus(),
        },
      }
    );

    const assetEntities = useMemo(() => {
      if (!useQueryResponse?.data || !Array.isArray(useQueryResponse.data)) {
        return [];
      }
      return useQueryResponse.data.map((asset: Asset) => AssetMapper.fromDTO(asset, fieldEntities));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(useQueryResponse?.data)]);

    const { tenant } = TenantRepository.useCurrentTenant();

    if (useQueryResponse?.failureReason && tenant?.id) {
      const { errCode, statusCode } = useQueryResponse.failureReason;
      if (errCode === 'ERR_NOT_FOUND' || statusCode === 404 || statusCode === 500) {
        AssetClassRepository.setAssetsFetch(false);
        if (!DialogRepository.getDialogOpenState()) {
          DialogRepository.openDialog({
            title: capitalCase(i18n.t('error')),
            message: i18n.t('field.inventory_field_configuration_issue'),
            action: ResetButton(async () => {
              await resetFieldConfiguration();
              DialogRepository.closeDialog();
              AssetClassRepository.setAssetsFetch(true);
              window.location.reload();
            }),
          });
        }
      }
    }

    return {
      ...useQueryResponse,
      assetEntities,
    };
  },

  useCreateAsset() {
    return useApiClientCreateAsset({
      mutation: {
        ...AssetRepository.getCreateAssetMutationConfig(),
      },
    });
  },

  getCreateAssetMutationConfig(): MutationOptions {
    const { onMutate, onError, onSuccess } = AssetRepository._createCallbacks(moduleQueryClient);

    return getCreateAssetMutationOptions({
      mutation: {
        ...defaultMutationOptions,
        onMutate,
        onSuccess,
        onError,
        mutationKey: ['createAsset'],
      },
    }) as MutationOptions;
  },

  // km 2/21/23: code from ReactQuery example: https://tanstack.com/query/v4/docs/react/guides/optimistic-updates#updating-a-list-of-todos-when-adding-a-new-todo
  _createCallbacks(queryClient: QueryClient) {
    return {
      // When mutate is called:
      onMutate: async ({
        params: { assetClassId },
        data,
      }: {
        params: { assetClassId: string };
        data: CreateAsset;
      }): Promise<undefined> => {
        commandBus.execute({
          payload: new CreateAssetCommand({
            externalId: data.id,
            assetClassId: assetClassId,
            attributes: data.attributes,
          }),
          type: 'CreateAssetCommand',
        });

        const currentTenantPid = TenantRepository.getCurrentTenantPid();
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(
          transformQueryKeyPathToArray(getGetTenantByIdQueryKey(currentTenantPid))
        );

        // TODO: the below commented code may be helpful in a follow up story for handling errors. I'll leave it here until then and add a note to the story. https://infoatplanitgeo.atlassian.net/browse/TP2-2332

        // Snapshot the previous value
        // const previousAssets: Asset[] | undefined = queryClient.getQueryData(
        //   transformQueryKeyPathToArray(getGetAssetsQueryKey(assetClassId))
        // );

        // const newAssetData: Asset = {
        //   assetClassId,
        //   id: data.id as string,
        //   attributes: data.attributes,
        // };

        // const assetUpdateFunction = (old: Asset[] | undefined): Asset[] => [
        //   ...(old || []),
        //   newAssetData,
        // ];

        // // Optimistically update to the new value
        // queryClient.setQueryData(
        //   transformQueryKeyPathToArray(getGetAssetsQueryKey(assetClassId)),
        //   assetUpdateFunction
        // );

        // // Return a context object with the snapshotted value
        // return { previousAssets } as CreateMutationContext;

        return undefined;
      },
      onSuccess: async (): Promise<void> => {
        // No need to refetch data, the data is updated in the event handler
      },
      onError: (
        error: { assetClassId: string; data: CreateAsset },
        variables: { params: { assetClassId: string }; data: CreateAsset },
        context: CreateMutationContext | undefined
      ): void => {
        // TODO: the below commented code may be helpful in a follow up story for handling errors. I'll leave it here until then and add a note to the story. https://infoatplanitgeo.atlassian.net/browse/TP2-2332
        // if (context) {
        // const currentTenantPid = TenantRepository.getCurrentTenantPid();
        // queryClient.setQueryData(
        //   transformQueryKeyPathToArray(getGetTenantByIdQueryKey(currentTenantPid)),
        //   context.previousAssets
        // );
        // }
      },
    };
  },

  useUpdateAsset() {
    return useApiClientUpdateAsset({
      mutation: {
        ...AssetRepository.getUpdateAssetMutationConfig(),
      },
    });
  },

  getUpdateAssetMutationConfig(): MutationOptions {
    const { onMutate, onError, onSuccess } = AssetRepository._updateCallbacks(moduleQueryClient);

    return getUpdateAssetMutationOptions({
      mutation: {
        ...defaultMutationOptions,
        onMutate,
        onSuccess,
        onError,
        mutationKey: ['updateAsset'],
      },
    }) as MutationOptions;
  },

  _updateCallbacks(queryClient: QueryClient) {
    return {
      onMutate: async ({
        params: { assetClassId },
        assetId,
        data,
      }: {
        params: { assetClassId: string };
        assetId: string;
        data: UpdateAsset;
      }): Promise<undefined> => {
        commandBus.execute({
          payload: new UpdateAssetCommand({
            id: assetId,
            assetClassId: assetClassId,
            attributes: data.attributes,
          }),
          type: 'UpdateAssetCommand',
        });

        const assetsQueryKey = transformQueryKeyPathToArray(getGetAssetsQueryKey({ assetClassId }));

        await queryClient.cancelQueries({
          queryKey: assetsQueryKey,
          // Without exact: true, react-query will invalidate all queries with this prefix
        });

        // TODO: the below commented code may be helpful in a follow up story for handling errors. I'll leave it here until then and add a note to the story. https://infoatplanitgeo.atlassian.net/browse/TP2-2332

        // const assetQueryKey = transformQueryKeyPathToArray(
        //   getGetAssetQueryKey(assetClassId, assetId)
        // );
        // Snapshot the previous value for both queries
        // const previousAsset: Asset | undefined = queryClient.getQueryData(assetQueryKey);
        // const previousAssets: Asset[] | undefined = queryClient.getQueryData(assetsQueryKey);

        // if (previousAsset) {
        //   const updatedAssetData: Asset = {
        //     id: previousAsset.id,
        //     assetClassId: previousAsset.assetClassId,
        //     attributes: {
        //       ...previousAsset.attributes,
        //       ...data.attributes,
        //     },
        //   };

        //   // Optimistically update to the new value
        //   queryClient.setQueryData(assetQueryKey, (): Asset => updatedAssetData);

        //   queryClient.setQueryData(assetsQueryKey, (oldAssetDTOs: Asset[] | undefined): Asset[] => {
        //     oldAssetDTOs = oldAssetDTOs || [];
        //     const itemIndex = oldAssetDTOs.findIndex((item) => item.id === updatedAssetData.id);

        //     if (itemIndex > -1) {
        //       oldAssetDTOs[itemIndex] = updatedAssetData;
        //     } else {
        //       oldAssetDTOs.push(updatedAssetData);
        //     }

        //     return oldAssetDTOs;
        //   });
        // }

        // // Return a context object with the snapshot
        // return {
        //   previousAsset,
        //   previousAssets,
        // } as UpdateMutationContext;

        return undefined;
      },
      onSuccess: async (): Promise<void> => {
        // No need to refetch data, the data is updated in the event handler
      },
      onError: (): void => {
        // TODO: the below commented code may be helpful in a follow up story for handling errors. I'll leave it here until then and add a note to the story. https://infoatplanitgeo.atlassian.net/browse/TP2-2332
        // if (context) {
        //   // TODO: Is this ok? It may not be if the user is logged in as a different tenant. Look into meta variable
        //   const currentTenantPid = TenantRepository.getCurrentTenantPid();
        //   const assetQueryKey = transformQueryKeyPathToArray(
        //     getGetAssetQueryKey(assetClassId, assetId)
        //   );
        //   const assetsQueryKey = transformQueryKeyPathToArray(
        //     getGetTenantByIdQueryKey(currentTenantPid)
        //   );
        //   queryClient.setQueryData(assetQueryKey, context.previousAsset);
        //   queryClient.setQueryData(assetsQueryKey, context.previousAsset);
        // }
      },
    };
  },

  useAsset(assetClassId: string, assetId: string) {
    const { fieldEntities } = FieldRepository.useGetFields(assetClassId);

    const useQueryResponse = useGetAsset(assetId, { assetClassId });

    const assetEntity = useMemo(() => {
      if (!useQueryResponse?.data) {
        return null;
      }
      return AssetMapper.fromDTO(useQueryResponse.data, fieldEntities);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(useQueryResponse?.data)]);

    const { tenant } = TenantRepository.useCurrentTenant();
    const { assetClass } = AssetClassRepository.useCurrentAssetClass(tenant?.id);
    if (useQueryResponse?.failureReason && assetClass?.id) {
      const axiosResponse = useQueryResponse?.failureReason as AxiosError;
      const status = axiosResponse.response?.status;
      if (status === 404) {
        this.refetchAsset(moduleQueryClient, assetClass.id, assetId).catch((err) => {
          loggerService.log(LoggerLevel.ERROR, err);
        });
        return {
          ...useQueryResponse,
          assetEntity: null,
        };
      }
    }

    return {
      ...useQueryResponse,
      assetEntity,
    };
  },

  async prefetchAssets(assetClassId: string) {
    const queryKey = transformQueryKeyPathToArray(getGetAssetsQueryKey({ assetClassId }));
    await moduleQueryClient.prefetchQuery({ queryKey, queryFn: () => getAssets({ assetClassId }) });
  },

  async getAsset(assetClassId: string, assetId: string, fields: FieldEntity[]) {
    const queryKey = transformQueryKeyPathToArray(getGetAssetQueryKey(assetId, { assetClassId }));
    const assetDto = await moduleQueryClient.fetchQuery({
      queryKey,
      queryFn: () => getAssetFromApi(assetId, { assetClassId }),
    });

    return AssetMapper.fromDTO(assetDto, fields);
  },

  async removeQueries(queryClient: QueryClient, assetClassId: string) {
    const getAssetsQueryKey = transformQueryKeyPathToArray(getGetAssetsQueryKey({ assetClassId }));
    await queryClient.removeQueries(getAssetsQueryKey);
  },

  async refetchQueries(queryClient: QueryClient, assetClassId: string) {
    const getAssetsQueryKey = transformQueryKeyPathToArray(getGetAssetsQueryKey({ assetClassId }));
    await queryClient.cancelQueries(getAssetsQueryKey, { exact: true });
    await queryClient.invalidateQueries(getAssetsQueryKey, { exact: true });
  },

  async refetchAsset(queryClient: QueryClient, assetClassId: string, assetId: string) {
    const getAssetQueryKey = transformQueryKeyPathToArray(
      getGetAssetQueryKey(assetId, { assetClassId })
    );
    await queryClient.invalidateQueries(getAssetQueryKey);
  },

  async refetchAssets(assetClassId: string) {
    const queryKey = getGetAssetsQueryKey({ assetClassId });
    await moduleQueryClient.refetchQueries({ queryKey });
  },

  async addAssetToLocalState(asset: AssetEntity) {
    const assetDto = AssetMapper.toDTO(asset);
    const currentTenantPid = TenantRepository.getCurrentTenantPid();
    // Cancel any outgoing refetches (so they don't overwrite our update)
    await moduleQueryClient.cancelQueries(
      transformQueryKeyPathToArray(getGetTenantByIdQueryKey(currentTenantPid))
    );

    // // Asset Update
    const assetQueryKey = transformQueryKeyPathToArray(
      getGetAssetQueryKey(asset.id, { assetClassId: asset.assetClassId })
    );
    await moduleQueryClient.cancelQueries({
      queryKey: assetQueryKey,
    });
    moduleQueryClient.setQueryData(assetQueryKey, (): Asset => assetDto);

    // Assets Update
    const assetsQueryKey = transformQueryKeyPathToArray(
      getGetAssetsQueryKey({ assetClassId: asset.assetClassId })
    );
    await moduleQueryClient.cancelQueries({
      queryKey: assetsQueryKey,
    });
    moduleQueryClient.setQueryData(assetsQueryKey, (old: Asset[] | undefined) => [
      ...(old || []),
      assetDto,
    ]);
  },

  async updateAssetLocalState(asset: AssetEntity) {
    const assetDto = AssetMapper.toDTO(asset);

    const currentTenantPid = TenantRepository.getCurrentTenantPid();
    // Cancel any outgoing refetches (so they don't overwrite our update)
    await moduleQueryClient.cancelQueries(
      transformQueryKeyPathToArray(getGetTenantByIdQueryKey(currentTenantPid))
    );

    // Asset Update
    const assetQueryKey = transformQueryKeyPathToArray(
      getGetAssetQueryKey(asset.id, { assetClassId: asset.assetClassId })
    );
    await moduleQueryClient.cancelQueries({
      queryKey: assetQueryKey,
    });
    moduleQueryClient.setQueryData(assetQueryKey, (): Asset => assetDto);

    // Assets Update
    const assetsQueryKey = transformQueryKeyPathToArray(
      getGetAssetsQueryKey({ assetClassId: asset.assetClassId })
    );
    await moduleQueryClient.cancelQueries({
      queryKey: assetsQueryKey,
    });
    moduleQueryClient.setQueryData(assetsQueryKey, (oldAssetDTOs: Asset[] | undefined): Asset[] => {
      oldAssetDTOs = oldAssetDTOs || [];
      const itemIndex = oldAssetDTOs.findIndex((item) => item.id === assetDto.id);

      if (itemIndex > -1) {
        oldAssetDTOs[itemIndex] = assetDto;
      } else {
        oldAssetDTOs.push(assetDto);
      }

      return oldAssetDTOs;
    });
  },
};

export default AssetRepository;
