import { Bus } from './bus';
import * as uuid from 'uuid';

export interface ICommand<T> {
  payload: T;
  id: string;
}

class CommandBus<T> extends Bus<ICommand<T>> {
  /**
   * Registers a handler for the specified command type
   * @param commandType The type of command to register the handler for
   * @param callback the callback to execute when a command of the specified type is registered
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public register(commandType: string, callback: ((...args: any[]) => void) | null = null): void {
    this.subscribeToType(commandType, callback);
  }

  /**
   * Executes the provided command by emitting it to the registered handler
   * @param command The command to execute
   */
  public execute({ payload, type, id }: { payload: T; type: string; id?: string }): string {
    id = id || uuid.v4();
    this.publishMessage({
      data: {
        type,
        payload,
        id,
      },
    });

    return id;
  }
}

const commandBus = new CommandBus();

export { commandBus };
