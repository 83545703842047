import { MutationCache, QueryClient } from '@tanstack/react-query';
import { ReactNode } from 'react';
import {
  PersistQueryClientProvider,
  PersistedClient,
  Persister,
} from '@tanstack/react-query-persist-client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import storageLocal from 'common/infrastructure/storageLocal/LocalForage';
import mutationProvider from './mutation.provider';

// km 1.30.23. i believe this has to be the same QueryClient instance for all
//   calls so they are available to persist in localForage
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      staleTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
  // Configure global cache callbacks
  mutationCache: new MutationCache({
    onSuccess: (data) => {
      console.log('Mutation complete');
    },
    onError: (error) => {
      console.error('Mutation error', error);
    },
  }),
});

export const clearReactQueryCache = async () => {
  queryClient.clear();
};

//https://stackoverflow.com/questions/41742390/javascript-to-check-if-pwa-or-mobile-web
function isPwa() {
  const displayModes = ['fullscreen', 'standalone', 'minimal-ui'];
  return displayModes.some(
    (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches
  );
}

// TODO: set up react-query cache busting: https://tanstack.com/query/v4/docs/react/plugins/persistQueryClient#cache-busting
function createStorageLocalPersister(
  key = isPwa() ? 'reactQueryPwa' : 'reactQuery',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  buster?: string
) {
  return {
    persistClient: async (client: PersistedClient) => {
      await storageLocal.setItem(key, JSON.stringify(client));
    },
    restoreClient: async (): Promise<PersistedClient | undefined> => {
      const client: string | undefined = await storageLocal.getItem(key);
      return client ? JSON.parse(client) : client;
    },
    removeClient: async () => {
      await storageLocal.removeItem(key);
    },
  } as Persister;
}

const persister = createStorageLocalPersister();

mutationProvider.init(queryClient);

interface Props {
  children: ReactNode;
}

export default function RemoteStateProvider({ children }: Props) {
  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister }}
      onSuccess={async () => {
        await queryClient.resumePausedMutations();
      }}
    >
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </PersistQueryClientProvider>
  );
}
