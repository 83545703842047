import { commandBus } from 'common/infrastructure/command.bus';
import { eventBus } from 'common/infrastructure/event.bus';
import { processCommand as createAssetCommandHandler } from 'assetClass/application/assetClass/asset/handler/createAsset.handler';
import { processEvent as assetCreatedEventHandler } from 'assetClass/application/assetClass/asset/handler/assetCreated.handler';
import { processCommand as updateAssetCommandHandler } from 'assetClass/application/assetClass/asset/handler/updateAsset.handler';
import { processEvent as assetUpdatedEventHandler } from 'assetClass/application/assetClass/asset/handler/assetUpdated.handler';
import { processEvent as assetUpdatedSyncFieldsEventHandler } from 'assetClass/application/assetClass/asset/handler/assetUpdatedSyncFields.handler';
import { processEvent as assetCreatedSyncFieldsEventHandler } from 'assetClass/application/assetClass/asset/handler/assetCreatedSyncFields.handler';
import { processCommand as fieldSyncUpdateAssetCommandHandler } from 'assetClass/application/assetClass/asset/handler/fieldSyncUpdateAsset.handler';

export const init = () => {
  // Register command and event handlers
  commandBus.register('CreateAssetCommand', createAssetCommandHandler);
  eventBus.subscribe('AssetCreatedEvent', assetCreatedEventHandler);
  eventBus.subscribe('AssetCreatedEvent', assetCreatedSyncFieldsEventHandler);

  commandBus.register('UpdateAssetCommand', updateAssetCommandHandler);
  eventBus.subscribe('AssetUpdatedEvent', assetUpdatedEventHandler);
  eventBus.subscribe('AssetUpdatedEvent', assetUpdatedSyncFieldsEventHandler);
  commandBus.register('FieldSyncUpdateAssetCommand', fieldSyncUpdateAssetCommandHandler);
  eventBus.subscribe('FieldSyncAssetUpdatedEvent', assetUpdatedEventHandler);
};
