/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TreePlotter v3 API
 * OpenAPI spec version: 0.6.0
 */

export type InputType = (typeof InputType)[keyof typeof InputType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InputType = {
  Text: 'Text',
  TextArea: 'TextArea',
  Number: 'Number',
  CheckBox: 'CheckBox',
  Radio: 'Radio',
  Select: 'Select',
  Date: 'Date',
  DateTime: 'DateTime',
  Photo: 'Photo',
  Time: 'Time',
  File: 'File',
  Image: 'Image',
  Email: 'Email',
  Phone: 'Phone',
  Color: 'Color',
  Range: 'Range',
  Hidden: 'Hidden',
  Password: 'Password',
  AutoComplete: 'AutoComplete',
  ListSearch: 'ListSearch',
  MultiSelect: 'MultiSelect',
  MultiCheckbox: 'MultiCheckbox',
  Double: 'Double',
  Switch: 'Switch',
} as const;
