import { Helmet } from 'react-helmet-async';
import { Grid, Container, Typography, Button, TextField } from '@mui/material';
import { useSettingsContext } from 'components/settings';
import { useLocales } from 'locales';
import { sentenceCase } from 'change-case';
import { externalLogout } from 'identityAndAccess/infrastructure/utils';
import loggerService, { LoggerLevel } from 'common/infrastructure/log';
import { clearReactQueryCache } from 'common/infrastructure/remoteState/persistQueryClient';
import { useTheme } from '@mui/material/styles';

const onError = (error: object) => {
  loggerService.log(LoggerLevel.ERROR, 'Error Caught by react-boundary!', error);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ErrorPage = ({ error, resetErrorBoundary }: { error: unknown; resetErrorBoundary: any }) => {
  const { themeStretch } = useSettingsContext();
  const { translate } = useLocales();
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title>{sentenceCase(translate('error'))}</title>
      </Helmet>

      <Container sx={{ marginTop: '10vh' }} maxWidth={themeStretch ? false : 'xl'}>
        <Typography variant="h4" sx={{ mb: 5 }}>
          {translate('error')}
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Typography sx={{ mb: 2 }}>{translate('error_detected')}</Typography>
            <Typography sx={{ mb: 2 }}>{translate('contact_support_if_persists')}</Typography>
            <Typography sx={{ mb: 3 }}>{translate('error_details_below')}</Typography>
            <TextField
              sx={{ mb: 5, color: 'red' }}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              value={(error as any)?.message || 'Error'}
              multiline
              fullWidth
              disabled
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Button
              sx={{ backgroundColor: theme.palette.error.dark }}
              variant="contained"
              onClick={async () => {
                const idToken = localStorage.getItem('idToken');
                localStorage.clear();
                localStorage.setItem('idToken', idToken || '');
                await clearReactQueryCache();
                resetErrorBoundary();
              }}
            >
              {translate('reset')}
            </Button>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                mr: 2,
              }}
            >
              <a
                style={{ textDecoration: 'none', color: 'inherit' }}
                href="mailto:support@planitgeo.com"
              >
                {translate('contact_support')}
              </a>
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={async () => {
                await externalLogout();
              }}
            >
              {translate('auth.logout')}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export { ErrorPage, onError };
