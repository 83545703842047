/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TreePlotter v3 API
 * OpenAPI spec version: 0.6.0
 */

export type DataType = (typeof DataType)[keyof typeof DataType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DataType = {
  Uuid: 'Uuid',
  Int: 'Int',
  Float: 'Float',
  Numeric: 'Numeric',
  Double: 'Double',
  Bool: 'Bool',
  Timestamp: 'Timestamp',
  Date: 'Date',
  Time: 'Time',
  Text: 'Text',
  Varchar: 'Varchar',
  Json: 'Json',
  GeomPoint: 'GeomPoint',
  GeomLine: 'GeomLine',
  GeomPolygon: 'GeomPolygon',
} as const;
