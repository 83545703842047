import { Message } from 'common/infrastructure/bus';
import { ICommand } from 'common/infrastructure/command.bus';
import { UpdateAssetCommand } from '@planitgeo/treeplotter-shared/lib/modules/asset-class/asset/domain/command/update-asset.command';
import { AssetEntity } from '@planitgeo/treeplotter-shared/lib/modules/asset-class/asset/domain/asset.model';
import FieldRepository from 'assetClass/infrastructure/field/repository';
import { eventBus } from 'common/infrastructure/event.bus';
import AssetRepository from 'assetClass/infrastructure/assetClass/asset/repository';

export const processCommand = async (command: Message<ICommand<UpdateAssetCommand>>) => {
  const { id: assetId, assetClassId, attributes } = command.data.payload.asset;

  const fields = await FieldRepository.getFields(assetClassId);
  const asset = new AssetEntity(
    {
      id: assetId,
      assetClassId: assetClassId,
      attributes,
    },
    fields
  );

  asset.update(attributes);

  await AssetRepository.updateAssetLocalState(asset);

  eventBus.publish({
    type: 'AssetUpdatedEvent',
    payload: asset.update({
      ...attributes,
    }),
  });
};
