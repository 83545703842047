/* eslint-disable no-unused-vars */
import { Mapper } from 'common/infrastructure/mapper';
import { AssetEntity } from '@planitgeo/treeplotter-shared/lib/modules/asset-class/asset/domain/asset.model';
import { Asset } from 'api-client';
import { FieldEntity } from '@planitgeo/treeplotter-shared/lib/modules/field/field/domain/field.model';

type AssetPersisted = Record<string, number | string | undefined | object>;

const toDTO = (asset: AssetEntity): Asset => {
  const { id, attributes, assetClassId } = asset;

  return {
    id,
    assetClassId,
    attributes,
  };
};

const fromDTO = (data: Asset, fields: FieldEntity[]): AssetEntity => new AssetEntity(data, fields);

const toPersistence = (asset: AssetEntity): AssetPersisted => {
  const { id, assetClassId, attributes, geometry } = asset;
  return {
    id: id,
    assetClassId,
    attributes,
    geometry,
  };
};

const notImplemented = () => {
  throw new Error('Not implemented');
};

export const AssetMapper: Mapper<AssetEntity, Asset> = {
  toDTO,
  fromDTO,
  toPersistence,
  fromPersistence: notImplemented,
};
