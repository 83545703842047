/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TreePlotter v3 API
 * OpenAPI spec version: 0.6.0
 */
import { useQuery } from '@tanstack/react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { Id, Tenant, UnauthorizedErrorResponse } from '.././model';
import { httpClient } from '../../../src/common/infrastructure/api/http-client';
import { queryMutator } from '../../../src/common/infrastructure/api/queryMutator';

/**
 * Fetch a list of tenants the user included in JWT has access to.
 * @summary Get tenants
 */
export const getTenants = (signal?: AbortSignal) => {
  return httpClient<Tenant[]>({ url: `/tenants`, method: 'GET', signal });
};

export const getGetTenantsQueryKey = () => {
  return [`/tenants`] as const;
};

export const useGetTenantsQueryOptions = <
  TData = Awaited<ReturnType<typeof getTenants>>,
  TError = UnauthorizedErrorResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getTenants>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTenantsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenants>>> = ({ signal }) =>
    getTenants(signal);

  const customOptions = queryMutator({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<Awaited<ReturnType<typeof getTenants>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetTenantsQueryResult = NonNullable<Awaited<ReturnType<typeof getTenants>>>;
export type GetTenantsQueryError = UnauthorizedErrorResponse;

/**
 * @summary Get tenants
 */
export const useGetTenants = <
  TData = Awaited<ReturnType<typeof getTenants>>,
  TError = UnauthorizedErrorResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getTenants>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetTenantsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Fetch a tenant by tenant ID.
 * @summary Get tenants
 */
export const getTenantById = (tenantId: Id, signal?: AbortSignal) => {
  return httpClient<Tenant>({ url: `/tenants/${tenantId}`, method: 'GET', signal });
};

export const getGetTenantByIdQueryKey = (tenantId: Id) => {
  return [`/tenants/${tenantId}`] as const;
};

export const useGetTenantByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getTenantById>>,
  TError = UnauthorizedErrorResponse | void
>(
  tenantId: Id,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getTenantById>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTenantByIdQueryKey(tenantId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenantById>>> = ({ signal }) =>
    getTenantById(tenantId, signal);

  const customOptions = queryMutator({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof getTenantById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTenantByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantById>>>;
export type GetTenantByIdQueryError = UnauthorizedErrorResponse | void;

/**
 * @summary Get tenants
 */
export const useGetTenantById = <
  TData = Awaited<ReturnType<typeof getTenantById>>,
  TError = UnauthorizedErrorResponse | void
>(
  tenantId: Id,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getTenantById>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetTenantByIdQueryOptions(tenantId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
