import { Mapper } from 'common/infrastructure/mapper';
import { ReferenceTableEntity } from '@planitgeo/treeplotter-shared/lib/modules/reference-table/reference-table/domain/reference-table.model';
import { ReferenceTableTag } from '@planitgeo/treeplotter-shared/lib/modules/reference-table/reference-table/domain/reference-table.interface';
import { ReferenceTable } from 'api-client';

const fromDTO = (data: ReferenceTable): ReferenceTableEntity =>
  new ReferenceTableEntity({
    id: data.id,
    tenantId: data.tenantId,
    name: data.name,
    tag: data.tag as ReferenceTableTag,
  });

const notImplemented = () => {
  throw new Error('Not implemented');
};

// there is "to/from store" and "from geojson"
export const ReferenceTableMapper: Mapper<ReferenceTableEntity, ReferenceTable> = {
  toDTO: notImplemented,
  fromDTO,
  toPersistence: notImplemented,
  fromPersistence: notImplemented,
};
