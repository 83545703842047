import { createSlice } from '@reduxjs/toolkit';
import { User as UserDto } from 'api-client';

type IUsersState = {
  users: Array<UserDto> | null;
};

const initialState: IUsersState = {
  users: null,
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    saveUsers(state: IUsersState, action: { payload: Array<UserDto> }) {
      return {
        ...state,
        users: action.payload,
      };
    },
  },
});

const { reducer } = slice;
const { saveUsers } = slice.actions;

if (!reducer) {
  throw new Error('No reducer found for users');
}

export { reducer, slice, saveUsers };
