/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TreePlotter v3 API
 * OpenAPI spec version: 0.6.0
 */

export type LengthMeasurementUnit =
  (typeof LengthMeasurementUnit)[keyof typeof LengthMeasurementUnit];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LengthMeasurementUnit = {
  in: 'in',
  ft: 'ft',
  yd: 'yd',
  mi: 'mi',
  mm: 'mm',
  cm: 'cm',
  m: 'm',
  km: 'km',
} as const;
