import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import LayoutBox from 'common/presentation/baseLayout/LayoutBox';
import ModalHeader from './ModalHeader';
import Main from 'common/presentation/baseLayout/Main';

export default function MockModalLayout() {
  const [modalTitle, setModalTitle] = useState('');
  const [beforeMockModalCloseHandler, setBeforeMockModalCloseHandler] = useState(() => () => true);

  const handleModalTitleChange = (title: string) => {
    setModalTitle(title);
  };

  return (
    <Main>
      <ModalHeader title={modalTitle} beforeMockModalCloseHandler={beforeMockModalCloseHandler} />

      <LayoutBox>
        <Outlet
          context={{
            onModalTitleChange: handleModalTitleChange,
            setBeforeMockModalCloseHandler,
          }}
        />
      </LayoutBox>
    </Main>
  );
}
