import { Navigate, useRoutes } from 'react-router-dom';
import AuthGuard from 'identityAndAccess/presentation/AuthGuard';
import BaseLayout from 'common/presentation/baseLayout/BaseLayout';
import MockModalLayout from 'assetClass/presentation/asset/MockModalLayout';
import { HomePage } from 'identityAndAccess/application/elements'; // move this to identityAndAccess/application/routes?
import {
  AssetClassPage,
  AssetClassRecordReadOnlyPage,
  AssetClassRecordEditablePage,
  AssetNewPage,
  SettingsPage,
  NoTenantPage,
} from './elements';
import IdentityAndAccessRoutes from 'identityAndAccess/application/routes';
import TenantRepository from 'common/infrastructure/tenant/repository';
import i18n from 'locales/i18n';

export default function Router() {
  const { tenant } = TenantRepository.useCurrentTenant();

  return useRoutes([
    {
      element: <AuthGuard />,
      children: [
        {
          path: '/',
          element: <BaseLayout />,
          children: [
            { element: <HomePage />, index: true },
            { path: 'work', element: <HomePage /> },
            { path: 'inspections', element: <HomePage /> },
          ],
        },
        {
          path: '/no-tenant-found',
          element: <NoTenantPage />,
        },
        {
          path: 'settings',
          element: <BaseLayout onlyBackButton />,
          children: [{ element: <SettingsPage />, index: true }],
        },
        {
          path: 'inventory',
          children: [
            { element: <Navigate to="map" replace />, index: true },
            {
              path: 'map',
              element: (
                <BaseLayout
                  noScroll
                  title={i18n.t('asset_class_with_tenant', { tenant: tenant?.name || '' })}
                />
              ),
              children: [{ element: <AssetClassPage />, index: true }],
            },
            {
              element: <MockModalLayout />,
              children: [
                {
                  path: ':asset_class_layer_id/:asset_id',
                  element: <AssetClassRecordReadOnlyPage />,
                },
              ],
            },
            {
              element: <MockModalLayout />,
              children: [
                {
                  path: ':asset_class_layer_id/:asset_id/edit',
                  element: <AssetClassRecordEditablePage />,
                },
              ],
            },
            {
              element: <MockModalLayout />,
              children: [
                {
                  path: ':asset_class_layer_id/new',
                  element: <AssetNewPage />,
                },
              ],
            },
          ],
        },
      ],
    },
    ...IdentityAndAccessRoutes,
  ]);
}
