//import { Link as RouterLink } from 'react-router-dom';
import { Stack /*, Link */ } from '@mui/material';
import { useLocales } from 'locales';
//import { PATH_AUTH } from 'routes/paths';
import LoginLayout from './LoginLayout';
import AuthLoginForm from './AuthLoginForm';
import Image from 'common/presentation/image';

export default function Login() {
  const { translate } = useLocales();

  return (
    <LoginLayout title={translate('login.greeting')}>
      <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
        <Image
          disabledEffect
          visibleByDefault
          alt="TreePlotter by PlanIT Geo"
          src={'/assets/planitgeo_assets/TP_Color_Vert_Tag.png'}
        />

        {/* TEMP DISABLE <Stack direction="row" spacing={0.5}>
          <Typography variant="body2">{translate('login.new_user')}</Typography>

          <Link to={PATH_AUTH.register} component={RouterLink} variant="subtitle2">
            {translate('auth.create_an_account')}
          </Link>
        </Stack>*/}
      </Stack>

      <AuthLoginForm />
    </LoginLayout>
  );
}
