import { dispatch, store } from 'redux/store';
import { getGetTenantsQueryKey, Tenant as TenantDto, useGetTenants } from 'api-client';
import { saveCurrent } from 'common/infrastructure/tenant/redux';
import { useAuthContext } from 'identityAndAccess/infrastructure/useAuthContext';
import { QueryClient } from '@tanstack/react-query';
import { transformQueryKeyPathToArray } from 'common/infrastructure/api/queryMutator';
import { externalLogout } from 'identityAndAccess/infrastructure/utils';
import { AxiosError } from 'axios';
import loggerService, { LoggerLevel } from '../log';
import ReferenceTableRepository from 'assetClass/infrastructure/referenceTable/repository';
import AssetClassRepository from 'assetClass/infrastructure/assetClass/repository';

const TenantRepository = {
  useTenants() {
    const { auth } = store.getState();
    const { isAuthenticated } = useAuthContext();

    const useQueryResponse = useGetTenants({
      query: {
        enabled: isAuthenticated && !!auth.user,
      },
    });

    const data: TenantDto[] | undefined = useQueryResponse?.data;
    if (!data || data?.length === 0) {
      return {
        ...useQueryResponse,
        tenantEntities: null,
      };
    }

    const tenantEntities = data; // TODO these aren't entities, user mapper fromDto

    return {
      ...useQueryResponse,
      tenantEntities,
    };
  },

  useCurrentTenant() {
    const currentTenantPid = this.getCurrentTenantPid();
    ReferenceTableRepository.useGetReferenceTables(currentTenantPid);
    AssetClassRepository.useCurrentAssetClass(currentTenantPid);

    const { tenantEntities, ...useQueryResponse } = TenantRepository.useTenants();

    if (!tenantEntities) {
      return { tenant: null, ...useQueryResponse };
    }

    if (useQueryResponse.failureReason) {
      const axiosResponse = useQueryResponse?.failureReason as AxiosError;
      const status = axiosResponse.response?.status;
      if (status === 404) {
        void externalLogout();
        return {
          tenant: null,
          ...useQueryResponse,
        };
      }
    }

    const tenant = tenantEntities.find((t) => t.id === currentTenantPid);

    // If no currentTenantPid or no tenant is found, default to first
    let tenantEntity;
    if (!currentTenantPid || !tenant) {
      tenantEntity = tenantEntities[0];
      this.saveCurrent(tenantEntities[0]?.id);
    } else {
      tenantEntity = tenant;
    }

    return {
      ...useQueryResponse,
      tenant: tenantEntity,
    };
  },

  getCurrentTenantPid() {
    // This doesn't require hooks
    const { tenants: tenantStore } = store.getState();
    const { currentTenantPid }: { currentTenantPid: string } = tenantStore;

    return currentTenantPid;
  },

  saveCurrent(tenantId: string) {
    dispatch(saveCurrent(tenantId));

    const { auth } = store.getState();
    if (auth.isAuthenticated) {
      this.preloadData(tenantId);
    }
  },

  preloadData(tenantId: string) {
    if (!tenantId) {
      return;
    }

    ReferenceTableRepository.prefetchReferenceTables(tenantId).catch((error) => {
      loggerService.log(LoggerLevel.ERROR, error);
    });

    AssetClassRepository.prefetchAssetClasses(tenantId).catch((error) => {
      loggerService.log(LoggerLevel.ERROR, error);
    });
  },

  async refetchTenants(queryClient: QueryClient) {
    await queryClient.invalidateQueries(transformQueryKeyPathToArray(getGetTenantsQueryKey()));
  },
};

export { TenantRepository as default };
