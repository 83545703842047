/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TreePlotter v3 API
 * OpenAPI spec version: 0.6.0
 */
import { useMutation } from '@tanstack/react-query';
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import type {
  AssetClass,
  ClearFieldConfigTenant,
  CreateAssetClass,
  CreateAssetClass201,
  CreateCategory,
  CreateField,
  CreateReferenceTable,
  CreateReferenceTable201,
  CreateTenant,
  CreateTenant201,
  CreateUser,
  CreateUser201,
  CreateUserTenantAssoc,
  Id,
  ImportFieldConfigTenant,
  ImportUser,
  ReferenceTable,
  Tenant,
  UnauthorizedErrorResponse,
  UpdateAssetClass,
  UpdateField,
  UpdateReferenceTable,
  UpdateTenant,
  UpdateUser,
  User,
} from '.././model';
import { httpClient } from '../../../src/common/infrastructure/api/http-client';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y
  ? 1
  : 2
  ? A
  : B;

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>;
}[keyof T];

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void
  ? I
  : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object ? NonReadonly<NonNullable<T[P]>> : T[P];
    }
  : DistributeReadOnlyOverUnions<T>;

export const createTenant = (createTenant: CreateTenant) => {
  return httpClient<CreateTenant201 | void>({
    url: `/tenants/create`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createTenant,
  });
};

export const getCreateTenantMutationOptions = <
  TError = void | UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTenant>>,
    TError,
    { data: CreateTenant },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createTenant>>,
  TError,
  { data: CreateTenant },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createTenant>>,
    { data: CreateTenant }
  > = (props) => {
    const { data } = props ?? {};

    return createTenant(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateTenantMutationResult = NonNullable<Awaited<ReturnType<typeof createTenant>>>;
export type CreateTenantMutationBody = CreateTenant;
export type CreateTenantMutationError = void | UnauthorizedErrorResponse;

export const useCreateTenant = <
  TError = void | UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTenant>>,
    TError,
    { data: CreateTenant },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createTenant>>,
  TError,
  { data: CreateTenant },
  TContext
> => {
  const mutationOptions = getCreateTenantMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateTenant = (tenantId: Id, updateTenant: UpdateTenant) => {
  return httpClient<Tenant>({
    url: `/tenants/${tenantId}/update`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: updateTenant,
  });
};

export const getUpdateTenantMutationOptions = <
  TError = UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTenant>>,
    TError,
    { tenantId: Id; data: UpdateTenant },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateTenant>>,
  TError,
  { tenantId: Id; data: UpdateTenant },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateTenant>>,
    { tenantId: Id; data: UpdateTenant }
  > = (props) => {
    const { tenantId, data } = props ?? {};

    return updateTenant(tenantId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateTenantMutationResult = NonNullable<Awaited<ReturnType<typeof updateTenant>>>;
export type UpdateTenantMutationBody = UpdateTenant;
export type UpdateTenantMutationError = UnauthorizedErrorResponse;

export const useUpdateTenant = <TError = UnauthorizedErrorResponse, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTenant>>,
    TError,
    { tenantId: Id; data: UpdateTenant },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateTenant>>,
  TError,
  { tenantId: Id; data: UpdateTenant },
  TContext
> => {
  const mutationOptions = getUpdateTenantMutationOptions(options);

  return useMutation(mutationOptions);
};
export const importFieldConfig = (
  tenantId: Id,
  importFieldConfigTenant: ImportFieldConfigTenant
) => {
  return httpClient<void>({
    url: `/tenants/${tenantId}/import-field-config`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: importFieldConfigTenant,
  });
};

export const getImportFieldConfigMutationOptions = <
  TError = void | UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof importFieldConfig>>,
    TError,
    { tenantId: Id; data: ImportFieldConfigTenant },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof importFieldConfig>>,
  TError,
  { tenantId: Id; data: ImportFieldConfigTenant },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof importFieldConfig>>,
    { tenantId: Id; data: ImportFieldConfigTenant }
  > = (props) => {
    const { tenantId, data } = props ?? {};

    return importFieldConfig(tenantId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ImportFieldConfigMutationResult = NonNullable<
  Awaited<ReturnType<typeof importFieldConfig>>
>;
export type ImportFieldConfigMutationBody = ImportFieldConfigTenant;
export type ImportFieldConfigMutationError = void | UnauthorizedErrorResponse;

export const useImportFieldConfig = <
  TError = void | UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof importFieldConfig>>,
    TError,
    { tenantId: Id; data: ImportFieldConfigTenant },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof importFieldConfig>>,
  TError,
  { tenantId: Id; data: ImportFieldConfigTenant },
  TContext
> => {
  const mutationOptions = getImportFieldConfigMutationOptions(options);

  return useMutation(mutationOptions);
};
export const clearFieldConfig = (tenantId: Id, clearFieldConfigTenant: ClearFieldConfigTenant) => {
  return httpClient<void>({
    url: `/tenants/${tenantId}/clear-field-config`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: clearFieldConfigTenant,
  });
};

export const getClearFieldConfigMutationOptions = <
  TError = void | UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof clearFieldConfig>>,
    TError,
    { tenantId: Id; data: ClearFieldConfigTenant },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof clearFieldConfig>>,
  TError,
  { tenantId: Id; data: ClearFieldConfigTenant },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof clearFieldConfig>>,
    { tenantId: Id; data: ClearFieldConfigTenant }
  > = (props) => {
    const { tenantId, data } = props ?? {};

    return clearFieldConfig(tenantId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ClearFieldConfigMutationResult = NonNullable<
  Awaited<ReturnType<typeof clearFieldConfig>>
>;
export type ClearFieldConfigMutationBody = ClearFieldConfigTenant;
export type ClearFieldConfigMutationError = void | UnauthorizedErrorResponse;

export const useClearFieldConfig = <
  TError = void | UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof clearFieldConfig>>,
    TError,
    { tenantId: Id; data: ClearFieldConfigTenant },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof clearFieldConfig>>,
  TError,
  { tenantId: Id; data: ClearFieldConfigTenant },
  TContext
> => {
  const mutationOptions = getClearFieldConfigMutationOptions(options);

  return useMutation(mutationOptions);
};
export const createUser = (createUser: CreateUser) => {
  return httpClient<CreateUser201 | void>({
    url: `/users/create`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createUser,
  });
};

export const getCreateUserMutationOptions = <
  TError = void | UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createUser>>,
    TError,
    { data: CreateUser },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createUser>>,
  TError,
  { data: CreateUser },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createUser>>,
    { data: CreateUser }
  > = (props) => {
    const { data } = props ?? {};

    return createUser(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateUserMutationResult = NonNullable<Awaited<ReturnType<typeof createUser>>>;
export type CreateUserMutationBody = CreateUser;
export type CreateUserMutationError = void | UnauthorizedErrorResponse;

export const useCreateUser = <
  TError = void | UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createUser>>,
    TError,
    { data: CreateUser },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createUser>>,
  TError,
  { data: CreateUser },
  TContext
> => {
  const mutationOptions = getCreateUserMutationOptions(options);

  return useMutation(mutationOptions);
};
export const importUser = (importUser: ImportUser) => {
  return httpClient<void>({
    url: `/users/import`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: importUser,
  });
};

export const getImportUserMutationOptions = <
  TError = void | UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof importUser>>,
    TError,
    { data: ImportUser },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof importUser>>,
  TError,
  { data: ImportUser },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof importUser>>,
    { data: ImportUser }
  > = (props) => {
    const { data } = props ?? {};

    return importUser(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ImportUserMutationResult = NonNullable<Awaited<ReturnType<typeof importUser>>>;
export type ImportUserMutationBody = ImportUser;
export type ImportUserMutationError = void | UnauthorizedErrorResponse;

export const useImportUser = <
  TError = void | UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof importUser>>,
    TError,
    { data: ImportUser },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof importUser>>,
  TError,
  { data: ImportUser },
  TContext
> => {
  const mutationOptions = getImportUserMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateUser = (userId: Id, updateUser: UpdateUser) => {
  return httpClient<User>({
    url: `/users/${userId}/update`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: updateUser,
  });
};

export const getUpdateUserMutationOptions = <
  TError = UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUser>>,
    TError,
    { userId: Id; data: UpdateUser },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateUser>>,
  TError,
  { userId: Id; data: UpdateUser },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateUser>>,
    { userId: Id; data: UpdateUser }
  > = (props) => {
    const { userId, data } = props ?? {};

    return updateUser(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateUserMutationResult = NonNullable<Awaited<ReturnType<typeof updateUser>>>;
export type UpdateUserMutationBody = UpdateUser;
export type UpdateUserMutationError = UnauthorizedErrorResponse;

export const useUpdateUser = <TError = UnauthorizedErrorResponse, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUser>>,
    TError,
    { userId: Id; data: UpdateUser },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateUser>>,
  TError,
  { userId: Id; data: UpdateUser },
  TContext
> => {
  const mutationOptions = getUpdateUserMutationOptions(options);

  return useMutation(mutationOptions);
};
export const createUserTenantAssoc = (userId: Id, createUserTenantAssoc: CreateUserTenantAssoc) => {
  return httpClient<void>({
    url: `/users/${userId}/tenants/create`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createUserTenantAssoc,
  });
};

export const getCreateUserTenantAssocMutationOptions = <
  TError = void | UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createUserTenantAssoc>>,
    TError,
    { userId: Id; data: CreateUserTenantAssoc },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createUserTenantAssoc>>,
  TError,
  { userId: Id; data: CreateUserTenantAssoc },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createUserTenantAssoc>>,
    { userId: Id; data: CreateUserTenantAssoc }
  > = (props) => {
    const { userId, data } = props ?? {};

    return createUserTenantAssoc(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateUserTenantAssocMutationResult = NonNullable<
  Awaited<ReturnType<typeof createUserTenantAssoc>>
>;
export type CreateUserTenantAssocMutationBody = CreateUserTenantAssoc;
export type CreateUserTenantAssocMutationError = void | UnauthorizedErrorResponse;

export const useCreateUserTenantAssoc = <
  TError = void | UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createUserTenantAssoc>>,
    TError,
    { userId: Id; data: CreateUserTenantAssoc },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createUserTenantAssoc>>,
  TError,
  { userId: Id; data: CreateUserTenantAssoc },
  TContext
> => {
  const mutationOptions = getCreateUserTenantAssocMutationOptions(options);

  return useMutation(mutationOptions);
};
export const createAssetClass = (createAssetClass: NonReadonly<CreateAssetClass>) => {
  return httpClient<CreateAssetClass201 | void>({
    url: `/asset-classes/create`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createAssetClass,
  });
};

export const getCreateAssetClassMutationOptions = <
  TError = void | UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createAssetClass>>,
    TError,
    { data: NonReadonly<CreateAssetClass> },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createAssetClass>>,
  TError,
  { data: NonReadonly<CreateAssetClass> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createAssetClass>>,
    { data: NonReadonly<CreateAssetClass> }
  > = (props) => {
    const { data } = props ?? {};

    return createAssetClass(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateAssetClassMutationResult = NonNullable<
  Awaited<ReturnType<typeof createAssetClass>>
>;
export type CreateAssetClassMutationBody = NonReadonly<CreateAssetClass>;
export type CreateAssetClassMutationError = void | UnauthorizedErrorResponse;

export const useCreateAssetClass = <
  TError = void | UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createAssetClass>>,
    TError,
    { data: NonReadonly<CreateAssetClass> },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createAssetClass>>,
  TError,
  { data: NonReadonly<CreateAssetClass> },
  TContext
> => {
  const mutationOptions = getCreateAssetClassMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateAssetClass = (assetClassId: Id, updateAssetClass: UpdateAssetClass) => {
  return httpClient<AssetClass>({
    url: `/asset-classes/${assetClassId}/update`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: updateAssetClass,
  });
};

export const getUpdateAssetClassMutationOptions = <
  TError = UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAssetClass>>,
    TError,
    { assetClassId: Id; data: UpdateAssetClass },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateAssetClass>>,
  TError,
  { assetClassId: Id; data: UpdateAssetClass },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAssetClass>>,
    { assetClassId: Id; data: UpdateAssetClass }
  > = (props) => {
    const { assetClassId, data } = props ?? {};

    return updateAssetClass(assetClassId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateAssetClassMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateAssetClass>>
>;
export type UpdateAssetClassMutationBody = UpdateAssetClass;
export type UpdateAssetClassMutationError = UnauthorizedErrorResponse;

export const useUpdateAssetClass = <
  TError = UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAssetClass>>,
    TError,
    { assetClassId: Id; data: UpdateAssetClass },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateAssetClass>>,
  TError,
  { assetClassId: Id; data: UpdateAssetClass },
  TContext
> => {
  const mutationOptions = getUpdateAssetClassMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteAssetClass = (assetClassId: Id) => {
  return httpClient<AssetClass>({ url: `/asset-classes/${assetClassId}/delete`, method: 'POST' });
};

export const getDeleteAssetClassMutationOptions = <
  TError = UnauthorizedErrorResponse | void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAssetClass>>,
    TError,
    { assetClassId: Id },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteAssetClass>>,
  TError,
  { assetClassId: Id },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteAssetClass>>,
    { assetClassId: Id }
  > = (props) => {
    const { assetClassId } = props ?? {};

    return deleteAssetClass(assetClassId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteAssetClassMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteAssetClass>>
>;

export type DeleteAssetClassMutationError = UnauthorizedErrorResponse | void;

export const useDeleteAssetClass = <
  TError = UnauthorizedErrorResponse | void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAssetClass>>,
    TError,
    { assetClassId: Id },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteAssetClass>>,
  TError,
  { assetClassId: Id },
  TContext
> => {
  const mutationOptions = getDeleteAssetClassMutationOptions(options);

  return useMutation(mutationOptions);
};
export const importAssets = (assetClassId: Id) => {
  return httpClient<void>({ url: `/asset-classes/${assetClassId}/import-assets`, method: 'POST' });
};

export const getImportAssetsMutationOptions = <
  TError = UnauthorizedErrorResponse | void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof importAssets>>,
    TError,
    { assetClassId: Id },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof importAssets>>,
  TError,
  { assetClassId: Id },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof importAssets>>,
    { assetClassId: Id }
  > = (props) => {
    const { assetClassId } = props ?? {};

    return importAssets(assetClassId);
  };

  return { mutationFn, ...mutationOptions };
};

export type ImportAssetsMutationResult = NonNullable<Awaited<ReturnType<typeof importAssets>>>;

export type ImportAssetsMutationError = UnauthorizedErrorResponse | void;

export const useImportAssets = <
  TError = UnauthorizedErrorResponse | void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof importAssets>>,
    TError,
    { assetClassId: Id },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof importAssets>>,
  TError,
  { assetClassId: Id },
  TContext
> => {
  const mutationOptions = getImportAssetsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const createField = (createField: CreateField) => {
  return httpClient<void>({
    url: `/fields/create`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createField,
  });
};

export const getCreateFieldMutationOptions = <
  TError = void | UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createField>>,
    TError,
    { data: CreateField },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createField>>,
  TError,
  { data: CreateField },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createField>>,
    { data: CreateField }
  > = (props) => {
    const { data } = props ?? {};

    return createField(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateFieldMutationResult = NonNullable<Awaited<ReturnType<typeof createField>>>;
export type CreateFieldMutationBody = CreateField;
export type CreateFieldMutationError = void | UnauthorizedErrorResponse;

export const useCreateField = <
  TError = void | UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createField>>,
    TError,
    { data: CreateField },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createField>>,
  TError,
  { data: CreateField },
  TContext
> => {
  const mutationOptions = getCreateFieldMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateField = (fieldId: Id, updateField: UpdateField) => {
  return httpClient<void>({
    url: `/fields/${fieldId}/update`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: updateField,
  });
};

export const getUpdateFieldMutationOptions = <
  TError = void | UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateField>>,
    TError,
    { fieldId: Id; data: UpdateField },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateField>>,
  TError,
  { fieldId: Id; data: UpdateField },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateField>>,
    { fieldId: Id; data: UpdateField }
  > = (props) => {
    const { fieldId, data } = props ?? {};

    return updateField(fieldId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateFieldMutationResult = NonNullable<Awaited<ReturnType<typeof updateField>>>;
export type UpdateFieldMutationBody = UpdateField;
export type UpdateFieldMutationError = void | UnauthorizedErrorResponse;

export const useUpdateField = <
  TError = void | UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateField>>,
    TError,
    { fieldId: Id; data: UpdateField },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateField>>,
  TError,
  { fieldId: Id; data: UpdateField },
  TContext
> => {
  const mutationOptions = getUpdateFieldMutationOptions(options);

  return useMutation(mutationOptions);
};
export const createCategory = (createCategory: CreateCategory) => {
  return httpClient<void>({
    url: `/field-categories/create`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createCategory,
  });
};

export const getCreateCategoryMutationOptions = <
  TError = void | UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCategory>>,
    TError,
    { data: CreateCategory },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createCategory>>,
  TError,
  { data: CreateCategory },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createCategory>>,
    { data: CreateCategory }
  > = (props) => {
    const { data } = props ?? {};

    return createCategory(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateCategoryMutationResult = NonNullable<Awaited<ReturnType<typeof createCategory>>>;
export type CreateCategoryMutationBody = CreateCategory;
export type CreateCategoryMutationError = void | UnauthorizedErrorResponse;

export const useCreateCategory = <
  TError = void | UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCategory>>,
    TError,
    { data: CreateCategory },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createCategory>>,
  TError,
  { data: CreateCategory },
  TContext
> => {
  const mutationOptions = getCreateCategoryMutationOptions(options);

  return useMutation(mutationOptions);
};
export const createReferenceTable = (createReferenceTable: NonReadonly<CreateReferenceTable>) => {
  return httpClient<CreateReferenceTable201 | void>({
    url: `/reference-tables/create`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createReferenceTable,
  });
};

export const getCreateReferenceTableMutationOptions = <
  TError = void | UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createReferenceTable>>,
    TError,
    { data: NonReadonly<CreateReferenceTable> },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createReferenceTable>>,
  TError,
  { data: NonReadonly<CreateReferenceTable> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createReferenceTable>>,
    { data: NonReadonly<CreateReferenceTable> }
  > = (props) => {
    const { data } = props ?? {};

    return createReferenceTable(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateReferenceTableMutationResult = NonNullable<
  Awaited<ReturnType<typeof createReferenceTable>>
>;
export type CreateReferenceTableMutationBody = NonReadonly<CreateReferenceTable>;
export type CreateReferenceTableMutationError = void | UnauthorizedErrorResponse;

export const useCreateReferenceTable = <
  TError = void | UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createReferenceTable>>,
    TError,
    { data: NonReadonly<CreateReferenceTable> },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createReferenceTable>>,
  TError,
  { data: NonReadonly<CreateReferenceTable> },
  TContext
> => {
  const mutationOptions = getCreateReferenceTableMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateReferenceTable = (
  referenceTableId: Id,
  updateReferenceTable: UpdateReferenceTable
) => {
  return httpClient<ReferenceTable>({
    url: `/reference-tables/${referenceTableId}/update`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: updateReferenceTable,
  });
};

export const getUpdateReferenceTableMutationOptions = <
  TError = UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateReferenceTable>>,
    TError,
    { referenceTableId: Id; data: UpdateReferenceTable },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateReferenceTable>>,
  TError,
  { referenceTableId: Id; data: UpdateReferenceTable },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateReferenceTable>>,
    { referenceTableId: Id; data: UpdateReferenceTable }
  > = (props) => {
    const { referenceTableId, data } = props ?? {};

    return updateReferenceTable(referenceTableId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateReferenceTableMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateReferenceTable>>
>;
export type UpdateReferenceTableMutationBody = UpdateReferenceTable;
export type UpdateReferenceTableMutationError = UnauthorizedErrorResponse;

export const useUpdateReferenceTable = <
  TError = UnauthorizedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateReferenceTable>>,
    TError,
    { referenceTableId: Id; data: UpdateReferenceTable },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateReferenceTable>>,
  TError,
  { referenceTableId: Id; data: UpdateReferenceTable },
  TContext
> => {
  const mutationOptions = getUpdateReferenceTableMutationOptions(options);

  return useMutation(mutationOptions);
};
