import { useSelector } from 'react-redux';
import ConfirmDialog from './ConfirmDialog';
import { RootStoreType } from 'redux/rootReducer';
import { Typography } from '@mui/material';

export default function Dialog() {
  const dialog = useSelector((state: RootStoreType) => state.dialog);
  let content;

  if (dialog.message && Array.isArray(dialog.message)) {
    content = dialog.message.map((m: string) => (
      <Typography sx={{ mb: 2 }} key={m}>
        {m}
      </Typography>
    ));
  }

  return (
    <ConfirmDialog
      open={dialog.isOpen}
      action={dialog.action}
      title={dialog.title}
      content={content || dialog.message}
      onClose={dialog.onClose}
    />
  );
}
