import { lazy } from 'react';
import { Loadable } from 'components/loading-screen/LoadingScreen';

export const AssetClassPage = Loadable(
  lazy(() => import('assetClass/presentation/AssetClassPage'))
);
export const AssetClassRecordReadOnlyPage = Loadable(
  lazy(() => import('assetClass/presentation/asset/RecordReadOnlyPage'))
);
export const AssetClassRecordEditablePage = Loadable(
  lazy(() => import('assetClass/presentation/asset/RecordEditablePage'))
);
export const AssetNewPage = Loadable(lazy(() => import('assetClass/presentation/asset/newPage')));
export const SettingsPage = Loadable(
  lazy(() => import('common/presentation/baseLayout/settings/SettingsPage'))
);
export const NoTenantPage = Loadable(lazy(() => import('common/presentation/NoTenantPage')));
