import axios, { AxiosRequestConfig, AxiosError, AxiosInstance } from 'axios';
import { TP3_API } from 'config';
import {
  externalLogout,
  getAuthorizationHeader,
  getBearerTokenFromHeader,
  isRefreshRequest,
  isAuthRequest,
  isTokenExpirationValid,
  refreshToken,
} from 'identityAndAccess/infrastructure/utils';
import { isLoggingRequest } from 'common/infrastructure/log';

interface Config extends AxiosRequestConfig {
  tpMobileRetryAttemptCount?: number;
}

let refreshing = false;
const errorTypeHeader = 'x-amzn-errortype';
const unauthorizedErrorValues = ['UnauthorizedException', 'Unauthorized', 'ERR_UNAUTHORIZED'];

/**
 * Interceptor to add Authorization header to requests
 * @param config any*
 * @returns config (Promise<InternalAxiosRequestConfig>)
    *@param config cannot have the type AxiosRequestConfig,
    otherwise the type system complains about a type mismatch,
    because the request interceptor expects an InteralAxiosRequestConfig type
*/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const authorizationInterceptor = async (config: any) => {
  const idToken = typeof window !== 'undefined' ? localStorage.getItem('idToken') : '';

  config.headers = config.headers ?? {};
  config.headers.Authorization = 'Bearer ' + idToken;

  return { ...config };
};

/**
 * Interceptor to refresh token if expired
 * @param request* any
 * @returns request Promise<InternalAxiosRequestConfig>
    *@param request cannot have the type AxiosRequestConfig,
    otherwise the type system complains about a type mismatch,
    because the request interceptor expects an InteralAxiosRequestConfig type
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const refreshTokenInterceptor = async (request: any) => {
  if (isRefreshRequest(request.url || '', request.method || '')) {
    return request;
  }
  await refreshTokenIfExpired(request);

  return request;
};

/**
 * Interceptor to handle successful responses
 * @param response any
 * @returns any
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const responseInterceptor = (response: any) => {
  if (isAuthRequest(response.config.url || '') || isLoggingRequest(response.config.url || '')) {
    return response;
  }
  // Orval stores this response in the React Query query. This query is saved to local storage,
  // which doesn't support functions on the response object.
  return response.data;
};

/**
 * Interceptor to handle errors
 * @param error Error | AxiosError
 * @returns Promise<any>
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const errorInterceptor = async (error: Error | AxiosError): Promise<any> => {
  if (!axios.isAxiosError(error)) {
    await externalLogout({
      skipRefreshTokenRevocation: true,
    });
    return Promise.reject('Something went wrong');
  }

  const { response } = error;
  const config = error.config as Config;

  if (isRefreshRequest(config?.url || '', config?.method || '')) {
    if (response?.status === 401) {
      // Token refresh request failed, likely due to an expired refresh token
      await externalLogout({
        skipRefreshTokenRevocation: true,
      });
      return;
    }
  }

  if (
    isResponseOk(response) &&
    error.config &&
    isFirstAttempt(config?.tpMobileRetryAttemptCount) &&
    isUnauthorizedError(response?.status as number, responseErrorInfo(response)) &&
    !isTokenRefreshing()
  ) {
    refreshing = true;
    const idToken = await refreshToken();
    if (idToken) refreshing = false;
    return retryRequestWithNewToken(idToken, error.config);
  }
  return Promise.reject((error.response && error.response.data) || 'Something went wrong');
};

/**
 * Creates an Axios instance with custom interceptors
 * @returns AxiosInstance
 */
const createAxiosInstance = (): AxiosInstance => {
  const instance = axios.create({
    baseURL: `${TP3_API.baseUrl}`,
  });

  instance.interceptors.request.use(authorizationInterceptor);
  instance.interceptors.request.use(refreshTokenInterceptor);

  instance.interceptors.response.use(responseInterceptor, errorInterceptor);

  return instance;
};

/**
 * Retries request with new token
 * @param idToken string
 * @param config Config
 * @returns Promise<any>
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const retryRequestWithNewToken = async (idToken: string, config: Config): Promise<any> => {
  // Axios issue:
  // Internally, Axios config.headers is mutated into an AxiosHeaders instance,
  // but the request method only accepts a plain headers object.
  // https://github.com/axios/axios/issues/5143
  config.headers = { ...config.headers };
  config.headers.Authorization = getAuthorizationHeader(idToken);
  config.tpMobileRetryAttemptCount = 1;

  return new Promise((resolve) => {
    resolve(axios(config));
  });
};

/**
 * Checks if token is actively refreshing
 * @returns boolean
 */
const isTokenRefreshing = (): boolean => refreshing;

/**
 * Checks if response is valid
 * @param response any
 * @returns boolean
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isResponseOk = (response: any): boolean =>
  response?.status && response?.headers && !!responseErrorInfo(response);

/**
 * Extracts error information from response
 * @param response any
 * @returns string
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const responseErrorInfo = (response: any): string =>
  response.headers[errorTypeHeader] || response?.statusText || response?.data?.errCode;

/**
 * Checks if error is unauthorized
 * @param status number
 * @param errorValue string
 * @returns boolean
 */
const isUnauthorizedError = (status: number, errorValue: string): boolean =>
  status === 401 && unauthorizedErrorValues.indexOf(errorValue) > -1;

/**
 * Checks if it's the first retry attempt
 * @param tpMobileRetryAttemptCount number | undefined
 * @returns boolean
 */
const isFirstAttempt = (tpMobileRetryAttemptCount: number | undefined): boolean => {
  if (tpMobileRetryAttemptCount === undefined) {
    return true;
  }

  return tpMobileRetryAttemptCount < 1;
};

/**
 * Refreshes token if expired
 * @param request AxiosRequestConfig
 * @returns Promise<void>
 */
const refreshTokenIfExpired = async (request: AxiosRequestConfig): Promise<void> => {
  if (request?.headers?.Authorization) {
    const idToken = getBearerTokenFromHeader(request.headers.Authorization as string);

    if (idToken && !isTokenExpirationValid(idToken) && !isTokenRefreshing()) {
      refreshing = true;
      const token = await refreshToken(idToken);
      if (token) refreshing = false;
    }
  }
};

export const axiosInstance = createAxiosInstance();

// create client for reactQuery/orval
export const httpClient = <T>(config: AxiosRequestConfig): Promise<T> =>
  axiosInstance({ ...config });
