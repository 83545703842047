import { useMemo } from 'react';
import { FieldEntity } from '@planitgeo/treeplotter-shared/lib/modules/field/field/domain/field.model';
import { InputType } from '@planitgeo/treeplotter-shared/lib/modules/field/field/domain/field.interface';
import { FieldMapper } from './mapper';
import {
  getGetFieldsQueryKey,
  useGetField,
  useGetFields,
  getFields as getFieldsFromApi,
} from 'api-client';
import { AxiosError } from 'axios';
import { queryClient as moduleQueryClient } from 'common/infrastructure/remoteState/persistQueryClient';
import AssetRepository from '../assetClass/asset/repository';
import AssetClassRepository from '../assetClass/repository';
import TenantRepository from 'common/infrastructure/tenant/repository';
import loggerService, { LoggerLevel } from 'common/infrastructure/log';
import { transformQueryKeyPathToArray } from 'common/infrastructure/api/queryMutator';
import { store } from 'redux/store';

const supportedInputTypes: string[] = [
  InputType.LIST_SEARCH,
  InputType.NUMBER,
  InputType.TEXT,
  InputType.TEXTAREA,
  InputType.PHOTO,
  InputType.HIDDEN,
  InputType.RADIO,
  InputType.CHECKBOX,
  InputType.DATE,
  InputType.SWITCH,
];

const FieldRepository = {
  useGetFields(referenceId = '') {
    const { auth } = store.getState();

    const useQueryResponse = useGetFields(
      { referenceId },
      {
        query: {
          enabled: !!referenceId && auth.isAuthenticated && !!auth.user,
        },
      }
    );

    const fieldEntities = useMemo(() => {
      if (!useQueryResponse?.data || !Array.isArray(useQueryResponse.data)) {
        return [];
      }
      const fieldEntities = useQueryResponse.data.map(FieldMapper.fromDTO);
      return fieldEntities.filter(this.isFieldInputTypeSupported);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(useQueryResponse?.data)]);

    const { tenant } = TenantRepository.useCurrentTenant();
    const { assetClass } = AssetClassRepository.useCurrentAssetClass(tenant?.id);
    if (useQueryResponse?.failureReason && assetClass?.id) {
      const axiosResponse = useQueryResponse?.failureReason as AxiosError;
      const status = axiosResponse.response?.status;
      if (status === 404) {
        this.refetchField(referenceId).catch((err) => {
          loggerService.log(LoggerLevel.ERROR, err);
        });
        AssetRepository.refetchAssets(assetClass.id).catch((err) => {
          loggerService.log(LoggerLevel.ERROR, err);
        });
        return {
          ...useQueryResponse,
          fieldEntities: [],
        };
      }
    }

    return {
      ...useQueryResponse,
      fieldEntities,
    };
  },

  useGetField(fieldId = '') {
    const { auth } = store.getState();

    const useQueryResponse = useGetField(fieldId, {
      query: {
        enabled: !!fieldId && auth.isAuthenticated && !!auth.user,
      },
    });

    const fieldEntity = useMemo(() => {
      if (!useQueryResponse?.data) {
        return null;
      }
      const fieldEntity = FieldMapper.fromDTO(useQueryResponse.data);

      if (!this.isFieldInputTypeSupported(fieldEntity)) {
        return null;
      }
      return fieldEntity;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(useQueryResponse?.data)]);

    return {
      ...useQueryResponse,
      fieldEntity,
    };
  },

  isFieldInputTypeSupported(field: FieldEntity) {
    return Object.values(supportedInputTypes).includes(field.inputType);
  },

  async prefetchFields(referenceId: string) {
    const queryKey = transformQueryKeyPathToArray(getGetFieldsQueryKey({ referenceId }));
    await moduleQueryClient.prefetchQuery({
      queryKey,
      queryFn: () => getFieldsFromApi({ referenceId }),
    });
  },

  async getFields(referenceId: string): Promise<FieldEntity[]> {
    const queryKey = transformQueryKeyPathToArray(getGetFieldsQueryKey({ referenceId }));
    const fieldsDto = await moduleQueryClient.fetchQuery({
      queryKey,
      queryFn: () => getFieldsFromApi({ referenceId }),
    });

    return fieldsDto.map(FieldMapper.fromDTO);
  },

  async refetchField(fieldId: string) {
    const queryKey = getGetFieldsQueryKey({ referenceId: fieldId });
    await moduleQueryClient.refetchQueries({ queryKey });
  },
};

export default FieldRepository;
