import { Mutex, MutexInterface } from 'async-mutex';

export interface Lock {
  mutex: MutexInterface;
  release: MutexInterface.Releaser | null;
}

export enum AssetClassStorageKeys {
  ASSETS_NEW = 'tempCreatedAssets',
  ASSETS_MODIFIED = 'tempModifiedAssets',
}

export abstract class BaseStorage {
  locks: {
    [key: string]: Lock;
  } = {};

  public async getLock(key: string): Promise<Lock> {
    let mutex: MutexInterface;
    if (this.locks[key]) {
      ({ mutex } = this.locks[key]);
    } else {
      mutex = new Mutex();
    }
    const release = await mutex.acquire();

    this.locks[key] = {
      mutex,
      release,
    };

    return this.locks[key];
  }

  public releaseLock(key: string): void {
    this.locks[key]?.release?.();
  }

  public abstract getItem(key: string, lock: Lock): Promise<unknown>;
  public abstract setItem(key: string, value: unknown, lock: Lock): Promise<void>;
  public abstract removeItem(key: string, lock: Lock): Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public abstract getStorageInstance(): any;
}
