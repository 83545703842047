import { Subject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface Message<T> {
  data: { type: string } & T;
}

export class Bus<T> {
  subject: Subject<Message<T>>;

  constructor() {
    this.subject = new Subject<Message<T>>();
  }

  /**
   * Subscribes to Message(s) of the specified type
   * @param type The type of Message.type to subscribe to
   * @param callback the callback to execute when a message of the specified type is published
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected subscribeToType(
    type: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback: ((...args: any[]) => void) | null = null
  ): void {
    const messages: Observable<Message<T>> = this.subject.pipe(
      filter((message: Message<T>) => message.data.type === type)
    );
    if (callback) {
      messages.subscribe((message) => callback(message));
    }
  }

  /**
   * Publishes the provided message to subscribers
   * @param message The message to publish
   */
  protected publishMessage(message: Message<T>): void {
    this.subject.next(message);
  }
}
