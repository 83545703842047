import { axiosInstance } from 'common/infrastructure/api/http-client';
import { AxiosRequestConfig } from 'axios';
import { APPLICATION, AUTH_API, AUTH_COGNITO } from 'config';
import loggerService, { LoggerLevel } from 'common/infrastructure/log';

function jwtDecode(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export const getBearerTokenFromHeader = (authHeader: string) => {
  if (!(authHeader && authHeader.startsWith('Bearer '))) {
    return '';
  }

  return authHeader.substring(7, authHeader.length);
};

export const isTokenExpirationValid = (token: string) => {
  if (!token) {
    return false;
  }

  const decoded = jwtDecode(token);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

// eslint-disable-next-line import/no-unused-modules
export const setTokenTimeout = (exp: number) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(async () => {
    if (axiosInstance.defaults.headers.common.Authorization) {
      await refreshToken();
    }
  }, timeLeft);
};

// ----------------------------------------------------------------------

export const setSession = (idToken: string | null) => {
  if (idToken) {
    localStorage.setItem('idToken', idToken);

    axiosInstance.defaults.headers.common.Authorization = getAuthorizationHeader(idToken);
  } else {
    clearSession();
  }
};

export const getAuthorizationHeader = (idToken: string) => `Bearer ${idToken}`;

// ----------------------------------------------------------------------

// eslint-disable-next-line import/no-unused-modules
export const clearSession = () => {
  localStorage.removeItem('idToken');
  delete axiosInstance.defaults.headers.common.Authorization;
};

export const externalLogout = async ({
  skipRefreshTokenRevocation = false,
}: {
  skipRefreshTokenRevocation?: boolean;
} = {}) => {
  if (!skipRefreshTokenRevocation) {
    try {
      // TODO: Show spinner
      await revokeRefreshToken();
    } catch (error) {
      loggerService.log(LoggerLevel.ERROR, 'Failed to revokeRefreshToken', error);
    }
  }

  clearSession();
  window.location.href = getLogoutUrl();
};

// ----------------------------------------------------------------------

// eslint-disable-next-line import/no-unused-modules
export const getRefreshTokenEndpoint = (): string => `${AUTH_API.baseUrl}${AUTH_API.tokenPath}`;

export const isAuthRequest = (url: string): boolean => url.startsWith(AUTH_API.baseUrl);

export const isRefreshRequest = (url: string, method: string): boolean => {
  if (method !== 'put') {
    return false;
  }

  return url === getRefreshTokenEndpoint();
};

export const refreshToken = async (idToken?: string): Promise<string> => {
  const config: AxiosRequestConfig = {};
  // If there is no id token, we can assume the access token is
  // already set as the default Authorization header.
  if (idToken) {
    config.headers = {
      Authorization: getAuthorizationHeader(idToken),
    };
  }

  try {
    const response = await axiosInstance.put(
      `${AUTH_API.baseUrl}${AUTH_API.tokenPath}`,
      {
        client_id: AUTH_API.clientId,
      },
      config
    );

    const { id_token: newIdToken } = response.data;
    setSession(newIdToken);

    return newIdToken;
  } catch (error) {
    loggerService.log(LoggerLevel.ERROR, 'Failed to return new id token', error);

    await externalLogout({
      skipRefreshTokenRevocation: true,
    });

    return '';
  }
};

/**
 * Remove the refresh token from the auth service
 * to prevent further refreshes of the ID token.
 */
// eslint-disable-next-line import/no-unused-modules
export const revokeRefreshToken = async (): Promise<void> =>
  axiosInstance.delete(`${AUTH_API.baseUrl}${AUTH_API.tokenPath}`, {
    data: {
      client_id: AUTH_API.clientId,
    },
  });

export const getLoginUrl = (): string => {
  const redirectUrl = encodeURIComponent(`${AUTH_COGNITO.redirectUrl}/auth/token`);
  const responseType = 'code';
  const scope = 'email+openid';

  interface CognitoQueryParamState {
    client_id: string;
    dev_redirect_url?: string;
  }
  const cognitoQueryParamState: CognitoQueryParamState = {
    client_id: AUTH_API.clientId,
  };
  if (APPLICATION.env === 'local') {
    cognitoQueryParamState.dev_redirect_url = AUTH_API.devRedirectUrl;
  }

  const encodedState = window.btoa(JSON.stringify(cognitoQueryParamState));

  return (
    `${AUTH_COGNITO.baseUrl}/login?` +
    `client_id=${AUTH_COGNITO.clientId}&` +
    `state=${encodedState}&` +
    `response_type=${responseType}&` +
    `scope=${scope}&` +
    `redirect_uri=${redirectUrl}`
  );
};

/**
 * Navigating to this endpoint will delete the AWS Cognito session.
 * The user will be redirected to the login page.
 */
// eslint-disable-next-line import/no-unused-modules
export const getLogoutUrl = (): string =>
  `${AUTH_COGNITO.baseUrl}/logout?client_id=${
    AUTH_COGNITO.clientId
  }&logout_uri=${encodeURIComponent(`${AUTH_COGNITO.clientRedirectUrl}/auth/login`)}`;

// eslint-disable-next-line import/no-unused-modules
export const getForgotPasswordUrl = (): string =>
  `${AUTH_COGNITO.baseUrl}/forgotPassword?client_id=${
    AUTH_COGNITO.clientId
  }&response_type=token&scope=email+openid&redirect_uri=${encodeURIComponent(
    `${AUTH_COGNITO.redirectUrl}/auth/cognito-callback`
  )}`;
