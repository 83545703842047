import { createContext, useEffect, useCallback, useContext } from 'react';
import { useAuthContext } from 'identityAndAccess/infrastructure/useAuthContext';
import { LogContextType, LogProviderProps } from './types';
import loggerService from '../log';

const LogContext = createContext<LogContextType | null>(null);

export function LogProvider({ children }: LogProviderProps) {
  const { isAuthenticated } = useAuthContext();

  const initialize = useCallback(() => {
    loggerService.init(isAuthenticated);
  }, [isAuthenticated]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <LogContext.Provider
      value={{
        method: 'log',
        logger: loggerService,
      }}
    >
      {children}
    </LogContext.Provider>
  );
}

export const useLogContext = () => {
  const context = useContext(LogContext);

  if (!context) throw new Error('useLogContext context must be use inside LogProvider');

  return context;
};
