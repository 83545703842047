import { Message } from 'common/infrastructure/bus';
import { IEvent } from 'common/infrastructure/event.bus';
import { AssetUpdatedEvent } from '@planitgeo/treeplotter-shared/lib/modules/asset-class/asset/domain/event/asset-updated.event';
import { FieldSyncAssetUpdatedEvent } from '@planitgeo/treeplotter-shared/lib/modules/asset-class/asset/domain/event/field-sync-asset-updated.event';

export const processEvent = async (
  event: Message<IEvent<AssetUpdatedEvent | FieldSyncAssetUpdatedEvent>>
) => {
  console.log('Asset updated', event.data.payload);
};
