import { createSlice } from '@reduxjs/toolkit';

type IState = {
  assetFetch: boolean;
};

const initialState: IState = {
  assetFetch: true,
};

const slice = createSlice({
  name: 'network',
  initialState,
  reducers: {
    setAssetsFetch(state: IState, action: { payload: boolean }) {
      return {
        ...state,
        assetFetch: action.payload,
      };
    },
  },
});

const { reducer } = slice;
const { setAssetsFetch } = slice.actions;

if (!reducer) {
  throw new Error('No reducer found for network slice');
}

// eslint-disable-next-line import/no-unused-modules
export { reducer, slice, setAssetsFetch };
