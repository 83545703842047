import { PATH_APP } from 'routes/paths';
import { Navigate } from 'react-router-dom';
import LoadingScreen from 'components/loading-screen/LoadingScreen';
import { ReactNode } from 'react';
import { useAuthContext } from '../infrastructure/useAuthContext';

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated, isInitialized } = useAuthContext();

  if (isAuthenticated) {
    return <Navigate to={PATH_APP.root} />;
  }

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
}
