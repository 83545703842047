import React, { useState, useEffect } from 'react';
import axios from 'axios';
import packageJson from '../../../../package.json';
import loggerService, { LoggerLevel } from 'common/infrastructure/log';

global.appVersion = packageJson.version;

const semverGreaterThan = (versionA: string, versionB: string): boolean => {
  const versionsA = versionA.split(/\./g);
  const versionsB = versionB.split(/\./g);

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());
    const b = Number(versionsB.shift());

    if (a === b) continue;

    return a > b || isNaN(b);
  }

  return false;
};

interface CacheBusterProps {
  children: (props: {
    loading: boolean;
    isLatestVersion: boolean;
    refreshCacheAndReload: () => void;
  }) => React.ReactNode;
}

const CacheBuster = ({ children }: CacheBusterProps) => {
  const [loading, setLoading] = useState(true);
  const [isLatestVersion, setIsLatestVersion] = useState(false);

  const refreshCacheAndReload = async () => {
    loggerService.log(LoggerLevel.INFO, 'Clearing cache and hard reloading...');
    if (caches) {
      try {
        const names = await caches.keys();
        for (const name of names) {
          await caches.delete(name);
        }
      } catch (error) {
        loggerService.log(LoggerLevel.ERROR, 'Failed to clear cache!!!', error);
      }
    }
    window.location.reload();
  };

  useEffect(() => {
    const fetchManifest = async () => {
      try {
        const response = await axios.get('/manifest.json');
        const meta = response.data;

        const latestVersion = meta.version;
        if (!latestVersion) {
          // if no version is found, allow app to continue loading
          loggerService.log(
            LoggerLevel.INFO,
            'No version was found! Continuing to load the application.'
          );
          setIsLatestVersion(true);
        } else {
          const currentVersion = global.appVersion;

          const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
          if (shouldForceRefresh) {
            loggerService.log(
              LoggerLevel.INFO,
              `We have a new version - ${latestVersion}. Should force refresh`
            );
            setIsLatestVersion(false);
          } else {
            loggerService.log(
              LoggerLevel.INFO,
              `You already have the latest version - ${latestVersion}.`
            );
            setIsLatestVersion(true);
          }
        }
        setLoading(false);
      } catch (error) {
        loggerService.log(
          LoggerLevel.ERROR,
          'Failed to fetch manifest!! Something is really wrong!',
          error
        );
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchManifest();
  }, []);

  return <> {children({ loading, isLatestVersion, refreshCacheAndReload })} </>;
};

export default CacheBuster;
