/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TreePlotter v3 API
 * OpenAPI spec version: 0.6.0
 */
import { useQuery } from '@tanstack/react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { GetUsersParams, UnauthorizedErrorResponse, User } from '.././model';
import { httpClient } from '../../../src/common/infrastructure/api/http-client';
import { queryMutator } from '../../../src/common/infrastructure/api/queryMutator';

/**
 * @summary Get users via tenant id
 */
export const getUsers = (params: GetUsersParams, signal?: AbortSignal) => {
  return httpClient<User[]>({ url: `/users`, method: 'GET', params, signal });
};

export const getGetUsersQueryKey = (params: GetUsersParams) => {
  return [`/users`, ...(params ? [params] : [])] as const;
};

export const useGetUsersQueryOptions = <
  TData = Awaited<ReturnType<typeof getUsers>>,
  TError = UnauthorizedErrorResponse | void
>(
  params: GetUsersParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUsersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsers>>> = ({ signal }) =>
    getUsers(params, signal);

  const customOptions = queryMutator({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getUsers>>>;
export type GetUsersQueryError = UnauthorizedErrorResponse | void;

/**
 * @summary Get users via tenant id
 */
export const useGetUsers = <
  TData = Awaited<ReturnType<typeof getUsers>>,
  TError = UnauthorizedErrorResponse | void
>(
  params: GetUsersParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetUsersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieve information about a user for user JWT.
 * @summary Get user
 */
export const getUser = (userId: string, signal?: AbortSignal) => {
  return httpClient<User>({ url: `/users/${userId}`, method: 'GET', signal });
};

export const getGetUserQueryKey = (userId: string) => {
  return [`/users/${userId}`] as const;
};

export const useGetUserQueryOptions = <
  TData = Awaited<ReturnType<typeof getUser>>,
  TError = UnauthorizedErrorResponse
>(
  userId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserQueryKey(userId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUser>>> = ({ signal }) =>
    getUser(userId, signal);

  const customOptions = queryMutator({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetUserQueryResult = NonNullable<Awaited<ReturnType<typeof getUser>>>;
export type GetUserQueryError = UnauthorizedErrorResponse;

/**
 * @summary Get user
 */
export const useGetUser = <
  TData = Awaited<ReturnType<typeof getUser>>,
  TError = UnauthorizedErrorResponse
>(
  userId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetUserQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
