/* eslint-disable no-unused-vars */
import { Mapper } from 'common/infrastructure/mapper';
import { ReferenceTableItemEntity } from '@planitgeo/treeplotter-shared/lib/modules/reference-table/reference-table-item/domain/reference-table-item.model';
import { ReferenceTableItem } from 'api-client';

type ReferenceTableItemPersisted = Record<string, number | string | undefined | object>;

const toDTO = (referenceTableItem: ReferenceTableItemEntity): ReferenceTableItem => {
  const { id, attributes, referenceTableId } = referenceTableItem;

  return {
    id,
    referenceTableId,
    attributes,
    tp2LookupTableItemId: null,
  };
};

const fromDTO = (data: ReferenceTableItem): ReferenceTableItemEntity =>
  new ReferenceTableItemEntity(data);

const toPersistence = (
  referenceTableItem: ReferenceTableItemEntity
): ReferenceTableItemPersisted => {
  const { id, referenceTableId, attributes } = referenceTableItem;
  return {
    id: id,
    referenceTableId,
    attributes,
  };
};

const notImplemented = () => {
  throw new Error('Not implemented');
};

export const ReferenceTableItemMapper: Mapper<ReferenceTableItemEntity, ReferenceTableItem> = {
  toDTO,
  fromDTO,
  toPersistence,
  fromPersistence: notImplemented,
};
