// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// locales
import ThemeLocalization from './locales';
// components
import SnackbarProvider from './components/snackbar';
import { ThemeSettings } from './components/settings';
import { MotionLazyContainer } from './components/animate';
import Dialog from './components/confirm-dialog/Dialog';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPage, onError } from 'common/presentation/ErrorPage';
// ----------------------------------------------------------------------

export default function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <ThemeLocalization>
            <SnackbarProvider>
              <Dialog />
              <ErrorBoundary FallbackComponent={ErrorPage} onError={onError}>
                <Router />
              </ErrorBoundary>
            </SnackbarProvider>
          </ThemeLocalization>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
