import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Drawer } from '@mui/material';
import { NAV } from 'config';
import Scrollbar from 'components/scrollbar';
import { NavSectionVertical } from '../nav-section';
import navConfig from './config';

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
};

export default function NavVertical({ openNav, onCloseNav }: Props) {
  const { pathname } = useLocation();

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Box
        component="img"
        src="/assets/planitgeo_assets/TP_Color_Horiz_Tag.png"
        sx={{ pt: 3, pb: 2, px: 2.5, cursor: 'pointer' }}
        alt="TreePlotter by PlanIT Geo"
      />
      <NavSectionVertical data={navConfig} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
      }}
    >
      <Drawer
        open={openNav}
        onClose={onCloseNav}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          sx: {
            width: NAV.W_DASHBOARD,
          },
        }}
      >
        {renderContent}
      </Drawer>
    </Box>
  );
}
