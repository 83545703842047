import { axiosInstance } from 'common/infrastructure/api/http-client';
import { TP_API } from '../../config';
import { Logger } from 'tslog';
import { dispatch, store } from '../../redux/store';
import { addLog, removeLog, resetLogs, overwriteLogs } from 'common/infrastructure/log/repository';
import { LogMessage, InitialLogMeta, ModifiedLogMeta } from './log/types';
import * as _ from 'lodash';
import sizeof from 'object-sizeof';
import TenantRepository from 'common/infrastructure/tenant/repository';
import {
  init as initTreePlotterSharedLogger,
  Logger as TpSharedLogger,
  LogType,
} from '@planitgeo/treeplotter-shared/lib/infrastructure/log/logger';

export enum LoggerLevel {
  DEBUG = 'debug',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
  FATAL = 'fatal',
}

export const isLoggingRequest = (url: string): boolean => url.startsWith(TP_API.baseUrl);

class LoggerService {
  init(isAuthenticated: boolean): void {
    setInterval(async () => {
      if (navigator.onLine && this.getLogCount() > 0 && isAuthenticated) {
        if (process.env.REACT_APP_ENV === 'local') {
          // clear out log data on local
          dispatch(resetLogs());
        } else {
          await this.syncLogs();
        }
      }
    }, 10000);
  }

  private _maxLogSize = 322122547; // 15% of 2GB (in bytes)

  private _currentLogSize = 0; // in bytes

  private _logger = new Logger({
    type: 'hidden',
    overwrite: {
      transportJSON: (logObjWithMeta) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let newLog: any = logObjWithMeta;
        newLog._meta = this.formatMeta(newLog._meta);
        newLog = this.formatLog(newLog);
        const logLevel = newLog.level;
        const withinLimit = this.checkLogSize(newLog);
        if (withinLimit) {
          dispatch(addLog(newLog));
        } else {
          this.evictLesserLogs(logLevel);
          // try dispatching addLog again
        }
      },
    },
  });

  // formats the log object to better align with our logzio filters
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private formatLog = (logObj: LogMessage): any => {
    // Remove nested _meta property
    logObj = _.merge(logObj, logObj._meta);
    delete logObj._meta;
    // If "0" or "1" is an object, flatten and remove
    // If a string, change key to "message"
    // If it is neither an object nor a string, drop it
    // tslog allows any number of arguments to be passed and logged
    // However, for our conventions we will only pass in 1 or 2 arguments
    const keys = ['0', '1'];
    keys.forEach((i) => {
      if (logObj[i]) {
        if (typeof logObj[i] === 'object') {
          logObj = _.merge(logObj, logObj[i]);
        } else if (typeof logObj[i] === 'string') {
          logObj.message = logObj[i] as string;
        }
        delete logObj[i];
      }
    });

    return logObj;
  };

  private formatMeta = (meta: InitialLogMeta): ModifiedLogMeta => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { path, runtime, name, logLevelId, logLevelName, date, ...rest } = meta;
    const tenantPid = TenantRepository.getCurrentTenantPid();

    // Overwrite date to be float instead of ISO8601 datetime string
    // Logz.io is set up to expect any property of "date" to be a float
    const newMeta = {
      tenant: tenantPid,
      level: LoggerLevel[logLevelName],
      date: Date.now(),
      tpMobileVersion: window.appVersion,
    };
    const modifiedMeta: typeof rest & ModifiedLogMeta = _.merge(newMeta, rest);
    return modifiedMeta;
  };

  private checkLogSize = (log: LogMessage): boolean => {
    const logSize = sizeof(log);
    if (this._currentLogSize + logSize < this._maxLogSize) {
      this._currentLogSize = this._currentLogSize + logSize;
      return true;
    }
    return false;
  };

  private evictLesserLogs = (logLevel: ModifiedLogMeta['level']): void => {
    // FATAL > ERROR > WARNING > INFO > DEBUG
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { logging } = store.getState();
    // TODO... sort logs by state and time, remove lesser/older logs
  };

  private removeLog = (log: LogMessage): void => {
    dispatch(removeLog(log));
  };

  private syncLogs = async (): Promise<void> => {
    const logs = this.getLogs();
    try {
      const response = await axiosInstance.post(`${TP_API.baseUrl}/v3/logs`, logs, {});
      if (response && response?.status === 200) {
        dispatch(resetLogs());
      }
    } catch (error) {
      if (error?.extraLogs) {
        dispatch(overwriteLogs(error.extraLogs));
      } else {
        this.log(LoggerLevel.FATAL, error);
      }
    }
  };

  getLogs(): LogMessage[] {
    const { logging } = store.getState();
    const { logs } = logging;
    return logs;
  }

  getLogCount(): number {
    const logs = this.getLogs();
    return logs.length;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  log(logLevel: LoggerLevel, message: string, jsonObj?: any): void {
    this._logger[logLevel](message, jsonObj);

    // log to console if env var is present
    if (process.env.REACT_APP_SHOW_LOGS) {
      if (logLevel === LoggerLevel.FATAL) {
        logLevel = LoggerLevel.ERROR;
      }
      if (jsonObj) {
        console[logLevel](message, jsonObj);
      } else {
        console[logLevel](message);
      }
    }
  }
}

const loggerService = new LoggerService();

export default loggerService;

const treePlotterSharedLogger: TpSharedLogger = {
  log(type: LogType, message: string, meta?: unknown) {
    switch (type) {
      case LogType.ERROR:
        loggerService.log(LoggerLevel.ERROR, message, meta);
        break;
      case LogType.WARN:
        loggerService.log(LoggerLevel.WARN, message, meta);
        break;
      case LogType.INFO:
        loggerService.log(LoggerLevel.INFO, message, meta);
        break;
      case LogType.HTTP:
        loggerService.log(LoggerLevel.INFO, message, meta);
        break;
      case LogType.VERBOSE:
        loggerService.log(LoggerLevel.INFO, message, meta);
        break;
      case LogType.DEBUG:
        loggerService.log(LoggerLevel.DEBUG, message, meta);
        break;
      case LogType.SILLY:
        loggerService.log(LoggerLevel.DEBUG, message, meta);
        break;
    }
  },

  error(message: string, meta?: unknown) {
    loggerService.log(LoggerLevel.ERROR, message, meta);
  },

  warn(message: string, meta?: unknown) {
    loggerService.log(LoggerLevel.WARN, message, meta);
  },

  info(message: string, meta?: unknown) {
    loggerService.log(LoggerLevel.INFO, message, meta);
  },

  http(message: string, meta?: unknown) {
    loggerService.log(LoggerLevel.INFO, message, meta);
  },

  verbose(message: string, meta?: unknown) {
    loggerService.log(LoggerLevel.INFO, message, meta);
  },

  debug(message: string, meta?: unknown) {
    loggerService.log(LoggerLevel.DEBUG, message, meta);
  },

  silly(message: string, meta?: unknown) {
    loggerService.log(LoggerLevel.DEBUG, message, meta);
  },
};

initTreePlotterSharedLogger(treePlotterSharedLogger);
