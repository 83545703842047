/* eslint-disable @typescript-eslint/no-explicit-any */
import { MutationKey, NetworkMode } from '@tanstack/react-query';

export interface MutationOptions {
  mutationKey: MutationKey;
  mutationFn: any;
  onMutate: (variables: any) => Promise<any | undefined> | any | undefined;
  onError: (err: any) => void;
  onSettled: (data: any, error: any | null) => void;
  networkMode: NetworkMode;
  retry: number;
}

export const defaultMutationOptions: {
  networkMode: NetworkMode;
  retry: boolean | number;
} = {
  networkMode: 'online',
  retry: true,
};
